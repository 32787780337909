let store = {
    debug: true,
    state: {
        menus: [],
    },

    saveMenus(menus) {
        if (this.debug)
            //console.log("setMessageAction triggered with", menus);
            this.state.menus = menus;
        sessionStorage.setItem("menus", JSON.stringify(this.state.menus));
    },
    getMenus() {
        if (sessionStorage.getItem("menus")) {
            this.state.menus = JSON.parse(sessionStorage.getItem("menus"));
        }
        if (this.debug)
            //console.log("clearMessageAction triggered", this.state.menus);

            return this.state.menus;
    },
};
export default store;
