/**
 * 全局URL
 * @type {string}
 */

export let srcUrl = "https://www.zdwy.online/wukong_pro";
export let fileUrl = "https://www.zdwy.online/files-pro/";

// export let srcUrl = "http://192.168.88.166:8082";
// export let srcUrl = "https://test.roadmaintain.cn:60001/wukong_test";
// export let fileUrl = "https://test.roadmaintain.cn:60001/wukong-file/";
// export let fileUrl = "https://www.zdwy.online/file-test/";

if (process.env.VUE_APP_ENV.trim() === "development") {
    srcUrl = "https://test.roadmaintain.cn:60001/wukong_test";
    // srcUrl = "http://192.168.88.202:8082"; //严
    // srcUrl = "http://192.168.88.251:8082"; //陶
    // srcUrl = "http://192.168.88.175:8082"; // 朱
    // fileUrl = "https://www.zdwy.online/file-test/";

    // srcUrl = "http://192.168.88.193:60001/wukong_test";
    //     srcUrl = "/api"
    // // srcUrl = "https://test.roadmaintain.cn:60001/wukong_test";
    // fileUrl = "https://test.roadmaintain.cn:60001/wukong-file/";
    fileUrl = "https://www.zdwy.online/file-test/";
    // fileUrl = "https://www.zdwy.online/files-pro/";
}

if (process.env.VUE_APP_ENV.trim() === "test") {
    srcUrl = "https://test.roadmaintain.cn:60001/wukong_test";
    fileUrl = "https://www.zdwy.online/file-test/";
}

// export const srcUrl = process.env.VUE_APP_BASE_API
/**
 * 添加课程时的默认封面路径
 * @type {string}
 */
export const addCourseDefaultImg =
    "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg";

/**
 * 添加素材时的默认封面路径
 * @type {string}
 */
export const addMaterialDefaultImg =
    "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg";

/**
 * 登录获取的登录账号返回的信息
 * @type {string}
 */
export const loginInfo = JSON.parse(sessionStorage.getItem("loginInfo"));

export const token = sessionStorage.Authorization;

export const CompanyType = sessionStorage.CompanyType;
export const RegionCode = sessionStorage.RegionCode;
export const Admin = sessionStorage.Admin;
export const CompanyId = sessionStorage.CompanyId;
