<template>
    <div>
        <el-upload
            :action="srcUrl + '/data/server/escort/web/file'"
            :headers="{
                Authorization: token,
            }"
            :file-list="fileVos"
            :limit="limit"
            accept=".rar,.zip,.doc,.doc,.pdf,.png,.jpg"
            :on-success="onSuccess"
            :on-remove="handleRemove"
            :on-exceed="onExceed"
        >
            <el-button type="primary"
                ><i class="el-icon-upload el-icon--right"></i
                >上传文件</el-button
            >
        </el-upload>
        <!-- <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog> -->
    </div>
</template>
<script>
import { fileUrl, srcUrl } from "@/api/Global";
export default {
    model: {
        prop: "fileVos",
        event: "change",
    },
    props: {
        fileVos: { type: Array, require: true },
        limit: { type: Number, default: 3 },
    },
    computed: {
        srcUrl() {
            return srcUrl;
        },
        fileUrl() {
            return fileUrl;
        },
    },
    data() {
        return {
            dialogImageUrl: "",
            dialogVisible: false,
            token: window.sessionStorage.getItem("Authorization"),
            // fileList: [],
        };
    },
    methods: {
        onExceed() {
            this.$message({
                message: "最多上传三份附件!",
                type: "warning",
                center: true,
            });
        },
        handleRemove(file) {
            const tempList = this.fileVos.filter(
                (item) => item.uid != file.uid
            );
            this.$emit("change", tempList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        onSuccess(file) {
            const tempFile = Object.assign(
                { name: file.data.fileName },
                file.data
            );
            const fileList = [...this.fileVos, ...[tempFile]];
            // const pathList = [...this.fileVos, ...[tempFile.filePath]];
            this.$emit("change", fileList);
        },
    },
};
</script>
