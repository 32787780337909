export function rowStyle({ rowIndex }) {
  if ((rowIndex + 1) % 2 === 0) {
    return 'background-color: #F5F9FC;'
  }
}

export function headerStyle() {
  return 'background-color: #EDF6FB;color:#333333;'
}

export function headerStyle1() {
  return 'background-color: #fff;color:#333333;'
}

export function userInfoHeaderStyle() {
  return 'background-color: #EAEEF9;font-weight: bold;'
}

