const src = '/course/server/escort/web/'
/**
 * 行管企业-首页(固定数据)
 * @type {string}
 */
export const companyIndex = src + 'company/index'

/**
 * 行管企业-首页(动态数据)
 * @type {string}
 */
export const companyDyIndex = src + 'company/dynamic/index'

/**
 * 普通企业-首页(固定数据)
 * @type {string}
 */
export const firmIndex = src + 'firm/index'

/**
 * 普通企业-首页(高频错题列表)
 * @type {string}
 */
export const firmQuIndex = src + 'firm/question/index'

/**
 * 普通企业-首页(学习完成人数、学习统计)
 * @type {string}
 */
export const firmStaIndex = src + 'firm/statistics/index'

/**
 * 普通企业-首页(未完成学员)
 * @type {string}
 */
export const firmTraIndex = src + 'firm/trainee/index'

export function proportionOfWrongQuestions() {}

export const getNotPassUserMsg = () => {}

export const getCompanyCourseStaticstice = () => {}

export const getCompanyAllCarNum = () => {}

export const companySet = () => {}
