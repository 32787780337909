export function hasBtn(userPermission) {
  // 当前按钮列表，我是用本地缓存存储
  const btnPermissionList = JSON.parse(sessionStorage.UserDetail).permissions
  // 因为后台返回的不是以按钮名的数组，所以需要过滤
  // const permission = btnPermissionList.map((v, i) => {
  //   return v
  // })
  // 是否在权限数组里面
  return btnPermissionList.includes(userPermission)
}
