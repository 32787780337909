<template>
    <section class="app-main">
        <transition name="fade-transform" mode="out-in">
            <!-- <keep-alive :include="cachedViews"> -->
            <!-- <router-view /> -->
            <slot />
            <!-- </keep-alive> -->
        </transition>
    </section>
</template>

<script>
export default {
    name: "AppMain",
    computed: {
        cachedViews() {
            return this.$store.state.tagsView.cachedViews;
        },
        key() {
            //console.log(this.$route.path);
            return this.$route.path;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.app-main {
    /* 50= navbar  50  */
    //min-height: calc(100% - #{$headBarHeight});
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.fixed-header + .app-main {
    //padding-top: 50px;
}

.hasTagsView {
    .app-main {
        /* 84 = navbar + tags-view = 50 + 34 */
        height: calc(100% - #{$navBarHeight} - #{$tagsHeight});
        //height: 100%;
    }

    .fixed-header + .app-main {
        //padding-top: 84px;
    }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
    .fixed-header {
        padding-right: 15px;
    }
}
</style>
