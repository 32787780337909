import Vue from "vue";

const requireComponent = require.context(
    "../components_new",
    false,
    /\w*\.(vue|jsx)/
);

requireComponent.keys().forEach((fileName) => {
    const _C = fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "");
    const conponentConfig = requireComponent(fileName);
    Vue.component(_C, conponentConfig.default || conponentConfig);
});
