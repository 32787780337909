import axios from "axios";
import { Message } from "element-ui";
import { srcUrl, fileUrl } from "@/api/Global";
import { Loading } from "element-ui";
// var JSONbig = require("json-bigint");

// 创建axios
const service = axios.create({
    baseURL: srcUrl, // 从文件里获得的URL，方便测试的时候频繁切换地址，srcUrl依然可以随时切换来自配置文件或自己手动设置的。并且srcUrl可以被引用在别的地方，方便调用
    timeout: 120000, // 请求接口响应时间
});

service.total = 0;

// 默认配置  转换响应数据
// service.defaults.transformResponse = [data => {
//   //console.log(data,'111111111111111111111111111111111');
//   // 对data（后台的原始数据）进行转换
//   // return JSONbig.parse(data)
// }]
// 请求拦截器
service.interceptors.request.use(
    (config) => {
        ++service.total;
        // console.log(service.total);
        if (service.total > 0) {
            service.loadingInstance = Loading.service({
                fullscreen: true,
                text: "数据同步中",
                spinner: "el-icon-loading",
                background: "rgba(0,0,0,0.5)",
            });
        }
        if (sessionStorage.getItem("Authorization")) {
            // 发送请求之前把 token 放进 headers 里面
            config.headers.Authorization = sessionStorage.getItem(
                "Authorization"
            );
        }
        return config;
    },
    (error) => {
        if (service.total > 0) {
            service.loadingInstance = Loading.service({
                fullscreen: true,
            });
        }
        ++service.total;
        // console.log(service.total);
        // 发送请求之前 报错 处理
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    (response) => {
        const res = response;
        --service.total;
        if (service.total < 1) {
            service.loadingInstance.close();
        }
        // console.log(service.total);
        // 接口响应200，并且返回的消息体的成功码为1（此情形是根据后端设置的返回体，因项目设计而异）
        // promise.resolve将返回数据回调

        if (res.status === 200) {
            if (res.data.code === 200) {
                return Promise.resolve(res.data);
            } else if (res.data.code === -1) {
                successHandle(res.data.code, res.data);
                return Promise.reject(res.data);
            } else {
                if (res.data.code) {
                    successHandle(res.data.code, res.data);
                    return Promise.reject(res.data);
                } else {
                    successHandle(res.data.code, res.data);
                    return Promise.resolve(res.data);
                }
            }
        } else {
            // promise.reject 拒绝，可在error回调获得，统一处理返回消息
            successHandle(res.data.code, res.data);
            return Promise.reject(res.data);
        }
    },
    (error) => {
        --service.total;
        // console.log(service.total);
        if (service.total < 1) {
            service.loadingInstance.close();
        }
        const { response } = error;

        if (response) {
            // 请求已发出，但是不在2xx的范围
            errorHandle(response.status, response.data.message);
            return Promise.reject(response);
        } else {
            // 处理断网的情况，接口响应超时，统一返回此消息
            Message.error("网络错误");
        }
    }
);

/**
 * 请求成功后的错误统一处理 status 200 ,code 1
 * @param {Number} status 请求失败的状态码
 * @param res
 */
const successHandle = (status, res) => {
    // 请求成功有可能会取data和msg里的值，所以参数是个对象
    // 状态码判断
    switch (status) {
        // 401 token过期
        case 401:
            sessionStorage.clear();
            localStorage.clear();
            console.log(window.location.href.split("#")[0]);

            // window.location.reload();
            window.location.href = window.location.href.split("#")[0];
            break;
        default:
            // eslint-disable-next-line no-case-declarations
            if (res.code) {
                const data = res.data;
                const msg = res.msg;
                if (data) {
                    Message.error(data + "" + msg);
                } else {
                    Message.error(msg);
                }
            }
            break;
    }
};

/**
 * 请求失败后的错误统一处理 status != 200
 * @param {Number} status 请求失败的状态码
 * @param msg
 */
const errorHandle = (status, msg) => {
    // 请求失败只展示消息，没有复杂情形，所以参数是个消息
    // 状态码判断
    switch (status) {
        // 404请求不存在
        case 404:
            Message.error("请求接口路径不存在！！！");
            break;
        case 500:
            // tip('请求的资源不存在',true,2000);
            // Message.error(other)
            Message.error(msg);
            break;
        default:
            Message.error("未知接口错误！！！");
    }
};

export default service;
export function axiosReq(url, data, params, method) {
    return service.request({
        baseURL: srcUrl,
        url,
        data,
        params,
        method,
    });
}
export function axiosReqCross(url, data, params, method) {
    return service.request({
        baseURL: fileUrl,
        url,
        data,
        params,
        method,
        headers: { "Content-Type": "application/json,charset=utf-8" },
    });
}
export function axiosGet(api, params) {
    return service.get(srcUrl + api, {
        params: params,
    });
}
export function axiosPost(api, data, params) {
    return service.post(srcUrl + api, data, {
        params: params,
    });
}
export function axiosPut(api, data, params) {
    return service.put(srcUrl + api, data, {
        params: params,
    });
}
export function axiosDel(api, params) {
    return service.delete(srcUrl + api, {
        params: params,
    });
}
export function axiosFile(url, data, params, method) {
    return service.request({
        baseURL: srcUrl,
        url,
        data,
        params,
        method,
        headers: { "content-type": "multipart/form-data" },
    });
}
