export default [
    {
        path: "/safeTrain/safeTrain",
        meta: { title: "安全培训" },
        component: () =>
            import(
                /* webpackChunkName: "safeTrain" */ "../pages/safeTrain/safeTrain/index.vue"
            ),
    },
    {
        path: "/safeTrain/rectification",
        meta: { title: "纠偏学习" },
        component: () =>
            import(
                /* webpackChunkName: "rectification" */ "../pages/safeTrain/rectification/index.vue"
            ),
    },
    {
        path: "/safeTrain/inspection",
        meta: { title: "车辆三检" },
        component: () =>
            import(
                /* webpackChunkName: "inspection" */ "../pages/safeTrain/inspection/index.vue"
            ),
    },
    {
        path: "/safeTrain/assessment",
        meta: { title: "状态测评" },
        component: () =>
            import(
                /* webpackChunkName: "assessment" */ "../pages/safeTrain/assessment/index.vue"
            ),
    },
    {
        path: "/safeTrain/creditEvaluation",
        meta: { title: "信用评价" },
        component: () =>
            import(
                /* webpackChunkName: "creditEvaluation" */ "../pages/safeTrain/creditEvaluation/index.vue"
            ),
    },
    {
        path: "/safeTrain/prevention",
        meta: { title: "主防报警" },
        component: () =>
            import(
                /* webpackChunkName: "prevention" */ "../pages/safeTrain/prevention/index.vue"
            ),
    },
    {
        path: "/safeTrain/healthWarning",
        meta: { title: "健康预警" },
        component: () =>
            import(
                /* webpackChunkName: "healthWarning" */ "../pages/safeTrain/healthWarning/index.vue"
            ),
    },
    {
        path: "/safeTrain/antiepidemic",
        meta: { title: "防疫统计" },
        component: () =>
            import(
                /* webpackChunkName: "antiepidemic" */ "../pages/safeTrain/antiepidemic/index.vue"
            ),
    },
    {
        path: "/safeTrain/energyManage",
        meta: { title: "能耗统计" },
        component: () =>
            import(
                /* webpackChunkName: "energyManage" */ "../pages/safeTrain/energyManage/HomeView.vue"
            ),
    },
];
