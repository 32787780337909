import variables from '@/styles/element-variables.scss'
import baseVariables from '@/styles/variables.scss'
import defaultSettings from '@/settings'

const { showSettings, tagsView,
  fixedHeader, sidebarLogo, hasUserInfo,
  hasHeaderBar, hasSideBar, hasBreadBar,
  menuMode, isDyMenu } = defaultSettings
const state = {
  theme: variables.theme,
  headBarHeight: baseVariables.headBarHeight,
  tagsHeight: baseVariables.tagsHeight,
  navBarHeight: baseVariables.navBarHeight,
  sideBarWidth: baseVariables.sideBarWidth,
  userInfoHeight: baseVariables.userInfoHeight,
  showSettings: showSettings,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  hasUserInfo: hasUserInfo,
  hasHeaderBar: hasHeaderBar,
  hasBreadBar: hasBreadBar,
  hasSideBar: hasSideBar,
  menuMode: menuMode,
  isDyMenu: isDyMenu
}

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  }
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

