<template>
    <div class="GlobalSearchPanelWrap">
        <slot />
    </div>
</template>

<script>
export default {
    name: "GlobalSearchPanelWrap",
    // 全局搜索包裹面板
};
</script>

<style lang="scss" scoped></style>
