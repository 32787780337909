const src = '/info/server/escort/web/'
/**
 * 企公告接口操作
 */
export const notice = src + 'notice/'
/**
 * 公告列表-发布
 */
export const noticeRelease = src + 'notice/release/' // {id}

/**
 * 通知公告查看阅读统计
 */
export const noticeCount = src + 'notice/count/' // {id}

/**
 * 通知公告查看阅读统计
 */
export const exportNotice = src + 'notice/export' // {id}

export function getUserNotice() {}

export const getReleaseNoticeUser = () => {}

export const getRelease = () => {}

export const getNoticeAllUser = () => {}
