export default [
    {
        path: "/sysConfig/dataDict",
        meta: { title: "数据字典" },
        component: () =>
            import(
                /* webpackChunkName: "dataDict" */ "../pages/sysConfig/dataDict/index.vue"
            ),
    },
    {
        path: "/sysConfig/timed_task",
        meta: { title: "定时任务" },
        component: () =>
            import(
                /* webpackChunkName: "timed_task" */ "../pages/sysConfig/timed_task/Home.vue"
            ),
    },
    {
        path: "/sysConfig/region",
        meta: { title: "区域配置" },
        component: () =>
            import(
                /* webpackChunkName: "region" */ "../pages/sysConfig/region/index.vue"
            ),
    },
    {
        path: "/sysConfig/courseType",
        meta: { title: "课程分类" },
        component: () =>
            import(
                /* webpackChunkName: "courseType" */ "../pages/sysConfig/courseType/index.vue"
            ),
    },
    {
        path: "/sysConfig/evaluationConfiguration",
        meta: { title: "测评配置" },
        component: () =>
            import(
                /* webpackChunkName: "evaluationConfiguration" */ "../pages/sysConfig/evaluationConfiguration/index.vue"
            ),
    },
    {
        path: "/sysConfig/violation",
        meta: { title: "档案配置" },
        component: () =>
            import(
                /* webpackChunkName: "violation" */ "../pages/sysConfig/violation/index.vue"
            ),
    },
    {
        path: "/sysConfig/allocatingCredit",
        meta: { title: "信用配置" },
        component: () =>
            import(
                /* webpackChunkName: "allocatingCredit" */ "../pages/sysConfig/allocatingCredit/index.vue"
            ),
    },
    {
        path: "/sysConfig/oplog",
        meta: { title: "操作日志" },
        component: () =>
            import(
                /* webpackChunkName: "oplog" */ "../pages/sysConfig/oplog/index.vue"
            ),
    },
    {
        path: "/sysConfig/correction",
        meta: { title: "纠偏学习配置" },
        component: () =>
            import(
                /* webpackChunkName: "correction" */ "../pages/sysConfig/correction/index.vue"
            ),
    },
    {
        path: "/sysConfig/dynaactionform",
        meta: { title: "动态表单" },
        component: () =>
            import(
                /* webpackChunkName: "dynaactionform" */ "../pages/sysConfig/dynaactionform/index.vue"
            ),
    },
    {
        path: "/sysConfig/carInspect",
        meta: { title: "车辆三检配置" },
        component: () =>
            import(
                /* webpackChunkName: "dynaactionform" */ "../pages/sysConfig/carInspect/Home.vue"
            ),
    },
];
