<template>
    <div id="app" class="w-100 h-100">
        <router-view />
    </div>
</template>

<script>
import Vue from "vue";

export default {
    data() {
        return {};
    },
    name: "App",
    watch: {
        $route: {
            handler() {
                try {
                    const token = sessionStorage.Authorization;
                    Vue.prototype.$token = token; // 挂载到Vue实例上面
                    Vue.prototype.$companyType = sessionStorage.CompanyType;
                    Vue.prototype.$regionCode = sessionStorage.RegionCode;
                    Vue.prototype.$provinceCode = sessionStorage.ProvinceCode;
                    Vue.prototype.$companyId = sessionStorage.CompanyId;
                    Vue.prototype.$admin = JSON.parse(sessionStorage.Admin);
                } catch (e) {
                    // console.log(e);
                }
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
@import "~@/styles/form.scss";

.el-dropdown-menu {
    border: none !important;
}

.companyNameAll .el-cascader__tags span:nth-child(2) {
    display: none;
}

// input::-webkit-input-placeholder {
//     //color: #666666 !important;
// }

// input::-moz-input-placeholder {
//     //color: #666666 !important;
// }

// input::-ms-input-placeholder {
//     //color: #666666 !important;
// }

.el-tooltip__popper {
    max-width: 1000px !important;
    line-height: 20px !important;
    background-color: #ffffff !important;
    color: #000000 !important;
    font-size: 13px !important;
    border: 1px solid #cccccc !important;
}

.centTopBox .el-input--prefix .el-input__inner {
    background-color: rgba(66, 243, 249, 0.1) !important;
    border: 0;
    color: #42f3f9 !important;
    font-size: 20px !important;
}

.centTopBox .el-range-editor .el-range-input {
    background-color: rgba(255, 255, 255, 0) !important;
    border: 0;
    color: #42f3f9;
    font-size: 20px !important;
}

.centTopBox .el-date-editor .el-range-separator {
    color: #ffffff !important;
}

.tableClass .vxe-table--render-default.border--full .vxe-table--header-wrapper {
    background-color: rgba(0, 232, 255, 0.1) !important;
    color: #00e8ff !important;
}

.tableClass .vxe-table--render-default .vxe-table--body-wrapper,
.vxe-table--render-default .vxe-table--footer-wrapper {
    background-color: rgba(255, 255, 255, 0) !important;
    color: #ffffff !important;
}

.tableClass .el-pagination__total,
.tableClass .el-pagination__jump {
    color: #ffffff !important;
}

.tableClass .el-pagination button:disabled {
    background-color: rgba(0, 232, 255, 0) !important;
    color: #ffffff !important;
}

.tableClass .el-pager li {
    color: #ffffff !important;
    background-color: rgba(0, 232, 255, 0) !important;
}

.tableClass .el-pager li.active {
    color: #42f3f9 !important;
}

.rowCent .el-progress-circle__track {
    background-color: #334a74 !important;
}

.ranking .el-progress-bar__outer {
    background-color: rgba(79, 172, 254, 0.5) !important;
}

.navList .el-progress-bar__outer {
    background-color: rgba(51, 133, 236, 0.3) !important;
}

.dateClass .el-input--prefix .el-input__inner {
    height: 30px;
    width: 220px;
}

.dateClass .el-input__icon {
    line-height: 30px;
}

.swiperClass .el-carousel__arrow--right,
.swiperClass .el-carousel__arrow--left {
    background-color: rgba(255, 255, 255, 0.05);
    width: 70px;
    height: 70px;
    font-size: 32px;
}

.operateClassN .centTopBox .el-input--prefix .el-input__inner {
    height: 30px;
}

.operateClassN .el-input__icon {
    line-height: 25px;
}

.boxShowBox .el-cascader,
.boxShowBox .el-select {
    width: 100% !important;
}

.boxInput .el-select {
    width: 100%;
}

.boxInput .searchInput .el-input {
    width: 100%;
}

.el-tabs--border-card > .el-tabs__content {
    padding: 10px;
}

.boxNowCont .el-card__body {
    padding: 0 !important;
}
</style>
