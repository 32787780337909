<template>
    <el-dialog
        center
        v-bind="$attrs"
        v-on="$listeners"
        width="40%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
    >
        <el-form
            class="g-el-form"
            ref="refElForm"
            label-width="120px"
            :model="ajaxParam"
            :rules="rules"
        >
            <el-form-item
                :label="`${'上传模板'}:`"
                placeholder="请选择公司"
                prop="key1"
            >
                <el-button type="primary" @click="onDownload"
                    >下载模板</el-button
                >
            </el-form-item>

            <el-form-item
                :label="`${'所属公司'}:`"
                placeholder="请选择公司"
                prop="companyId"
                v-if="$admin"
            >
                <GlobalCompanySelect v-model="ajaxParam.companyId">
                </GlobalCompanySelect>
            </el-form-item>
            <el-form-item label="上传文件" :required="true">
                <el-button type="primary" @click="inputFlieClick"
                    >点击上传</el-button
                >
                <input
                    ref="inputer"
                    type="file"
                    id="fileId"
                    class="inputFlieNone"
                    @change="getFile"
                />
                <div v-if="inputFlieText" class="inputText">
                    {{ inputFlieText }}
                </div>
            </el-form-item>
            <!-- <el-form-item
                :label="`${'上传文件'}:`"
                placeholder="请选择公司"
                prop="file"
            >
                <el-upload
                    :http-request="httpRequest"
                    ref="upload"
                    :limit="1"
                    :action="BaseApiUrl"
                    :file-list="fileList"
                    :auto-upload="false"
                    :on-exceed="handleExceed"
                    :on-change="handlePreview"
                >
                    <el-button slot="trigger" size="small" type="primary"
                        >选取文件</el-button
                    >

                </el-upload>
            </el-form-item> -->
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: center">
            <el-button type="primary" @click="submitData">确定</el-button>
            <el-button type="info" @click="onCancel">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { srcUrl } from "@/api/Global";
import { rules } from "./rules";
import { bytesToFile } from "@/utils/handleByte";
export default {
    title: "批量导出",
    computed: {
        rules() {
            return rules;
        },
        BaseApiUrl() {
            return srcUrl + this.uploadPath;
        },
    },
    name: "GlobalBatchExport",
    props: {
        downloadPath: {
            type: String,
            required: true,
        },
        uploadPath: {
            type: String,
            required: true,
        },
        op: {
            type: Object,
            default: () => {
                return {
                    id: "0",
                    show: false,
                    // row: null,
                    code: "init",
                };
            },
        },
    },
    data() {
        return {
            ajaxParam: {
                companyId: this.$admin ? "" : this.$companyId, //所属公司
                file: "", //附件
            },
            inputFlieText: "",
            fileList: [
                // {
                //     name: "food.jpeg",
                //     url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
                // },
            ],
        };
    },
    created() {},
    methods: {
        async onDownload() {
            let { code, data } = await this.$api.get(this.downloadPath);
            if (code == 200) {
                bytesToFile(data.bytes, data.fileName);
            }
        },
        inputFlieClick() {
            var input = this.$refs.inputer;
            input.click();
        },

        getFile() {
            console.log("getFile");
            var that_ = this;
            const inputFile = that_.$refs.inputer.files[0];
            that_.inputFlieText = inputFile.name;
        },

        httpRequest(options) {
            console.log(options.file);
            let formData = new FormData();
            if (options) {
                // this.ajaxParam.file = options.file;
                let reader = new FileReader();
                reader.readAsBinaryString(options.file);
                formData.append("file", options.file);
                formData.append("companyId", this.ajaxParam.companyId);
                if (
                    options.file.type == "application/vnd.ms-excel" ||
                    options.file.type ==
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    this.submitData(formData);
                } else {
                    this.$message({
                        message: "只能上传xls/xlsx格式的文件",
                        type: "warning",
                        center: true,
                    });
                }
            }
        },
        handlePreview(file) {
            this.ajaxParam.file = file;
        },

        // eslint-disable-next-line no-unused-vars
        handleExceed(files, fileList) {
            console.log(files);
            this.$message({
                message:
                    "每次只能上传一个xls/xlsx文件,删除已存在文件后从新上传",
                type: "warning",
                center: true,
            });
        },

        onConfirm() {
            this.$refs.refElForm.validate((valid) => {
                if (valid) {
                    this.$refs.upload.submit();
                }
            });

            // if (!this.ajaxParam.file) {
            //     this.$message({
            //         message: "选择xls/xlsx格式的文件上传",
            //         type: "warning",
            //         center: true,
            //     });
            // }

            // this.$refs.refElForm.validate((valid) => {
            //     if (valid) {
            //         this.submitData();
            //     }
            // });
        },

        async submitData() {
            const inputFile = this.$refs.inputer.files[0];
            if (!inputFile) {
                this.inputFlieText = "";
                this.$message.error("请上传正确的文件");
                return false;
            }

            inputFile
                .slice(0, 1) // only the first byte
                .arrayBuffer() // try to read
                .then(() => {
                    // 文件没改变，在这里可以发请求了
                    const formData = new FormData();
                    formData.append("file", inputFile);
                    formData.append("companyId", this.ajaxParam.companyId);
                    // 提交(编辑)数据
                    this.$api
                        .post(this.uploadPath, formData, {
                            params: formData,
                            headers: {
                                "content-type": "multipart/form-data",
                            },
                        })
                        .then(({ code }) => {
                            if (code == 200) {
                                this.$emit("query");
                                this.$message({
                                    message: "上传成功",
                                    type: "success",
                                    center: true,
                                });
                                this.onCancel();
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            this.ajaxParam.file = null;
                            this.fileList = [];
                            // this.$refs.upload.clearFiles();
                        });
                })
                .catch(() => {
                    // 文件有问题，在这里终止掉
                    console.log("failed to read");
                    this.$message({
                        message: "文件发送改变，请重新上传!",
                        type: "warning",
                        center: true,
                    });
                    this.inputFlieText = ""; // 把缓存的file清空
                    this.$refs.inputer.value = "";
                });

            // console.log(res);
            // if (code == 200) {

            //     this.onCancel();
            // } else {
            //     debugger;

            // }
        },
        onCancel() {
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss" scoped>
.g-el-form {
    padding: 0 100px;
}
.upload-demo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
::v-deep .el-dialog__header {
    border-bottom: 1px solid #c3c3c3;
}
.inputFlieNone {
    width: 150px;
    height: 40px;
    display: none;
}

.inputText {
    font-size: 16px;
}
</style>
