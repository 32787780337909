export default [
    {
        path: "/businessCnf/standardCourse",
        meta: { title: "课程管理" },
        component: () =>
            import(
                /* webpackChunkName: "standardCourse" */ "../pages/businessCnf/standardCourse/index.vue"
            ),
    },
    {
        path: "/businessCnf/exposurePlatform",
        meta: { title: "曝光台" },
        component: () =>
            import(
                /* webpackChunkName: "exposurePlatform" */ "../pages/businessCnf/exposurePlatform/index.vue"
            ),
    },
    {
        path: "/infoManage/notice",
        meta: { title: "公告管理" },
        component: () =>
            import(
                /* webpackChunkName: "notice" */ "../pages/businessCnf/notice/index.vue"
            ),
    },
    {
        path: "/businessCnf/trainingCourseware",
        meta: { title: "培训课件" },
        component: () =>
            import(
                /* webpackChunkName: "trainingCourseware" */ "../pages/businessCnf/trainingCourseware/index.vue"
            ),
    },
    {
        path: "/businessCnf/msgJudge",
        meta: { title: "留言评价" },
        component: () =>
            import(
                /* webpackChunkName: "msgJudge" */ "../pages/businessCnf/msgJudge/index.vue"
            ),
    },
    {
        path: "/businessCnf/evaluationScale",
        meta: { title: "测评量表" },
        component: () =>
            import(
                /* webpackChunkName: "evaluationScale" */ "../pages/businessCnf/evaluationScale/index.vue"
            ),
    },
];
