/**
 * 将一维的扁平数组转换为多层级对象
 * @param  {[type]} list 一维数组，数组中每一个元素需包含id和parent_id两个属性
 * @return {[type]} tree 多层级树状结构
 */

export function transData(a, idStr, pidStr, chindrenStr) {
    const r = [];
    const hash = {};
    const id = idStr;
    const pid = pidStr;
    let children = [];
    children = chindrenStr;
    let i = 0;
    let j = 0;
    const len = a.length;
    for (; i < len; i++) {
        hash[a[i][id]] = a[i];
    }
    for (; j < len; j++) {
        const aVal = a[j];
        const hashVP = hash[aVal[pid]];
        if (hashVP) {
            !hashVP[children] && (hashVP[children] = []);
            hashVP[children].push(aVal);
        } else {
            r.push(aVal);
        }
    }
    return r;
}

/**
 * 将树级菜单转为平面级
 */
const newArr = [];
export function flatData(arr, children) {
    arr.forEach((item) => {
        if (item.type < 2) {
            newArr.push(item);
            item[children] && item[children].length > 0
                ? flatData(item[children])
                : null;
            item[children] = null; // 如果扁平化后的数组需要显示父子层级，可以把这一项删除
        }
    });
    return newArr;
}
