
export function bytesToFile(arr, fileName) {
  var blob = dataURLtoBlob(arr)
  const blobURL = window.URL.createObjectURL(blob)
  const tempLink = document.createElement('a')// 创建a标签去下载
  tempLink.style.display = 'none'
  tempLink.href = blobURL
  tempLink.setAttribute('download', fileName)
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank')
  }
  document.body.appendChild(tempLink)
  tempLink.click()
  document.body.removeChild(tempLink)
  window.URL.revokeObjectURL(blobURL)
}

function dataURLtoBlob(dataurl) {
  const bstr = atob(dataurl)
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: 'application/vnd.ms-excel;charset=UTF-8;' })
}
