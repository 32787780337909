/**
 * 输入框提示必填
 * @param msg 提示信息
 * @returns
 */
export function requiredInput(msg) {
    return {
        required: true,
        message: msg || "该项为必填项",
        trigger: "change",
    };
}

/**
 *选项框提示必填
 * @returns
 */
export function requiredSelect(msg) {
    return {
        required: true,
        message: msg || "该项为必选项",
        trigger: ["change", "blur"],
    };
}

/**
 * 字符提示最大个数值
 * @param max
 * @returns
 */
export function charMax(max) {
    return {
        min: 0,
        max: max,
        message: `字符长度不超过${max}个字符`,
        trigger: "change",
    };
}

// 全局通用表单验证函数

/**
 * 排序规则验证
 * @param rule
 * @param value
 * @param callback
 */
export function sortFn() {
    return {
        trigger: "change",
        validator: (rule, value, callback) => {
            if (!/^\d*$/.test(value + "")) {
                callback(new Error("排序只能输入0或正整数"));
            } else if ((value + "").length > 5) {
                callback(new Error("排序字符长度不超过5个字符"));
            } else {
                callback();
            }
        },
    };
}

export function priceFn() {
    return {
        trigger: "change",
        validator: (rule, value, callback) => {
            if (!/^[0-9]+([.]{1}[0-9]{0,2}){0,1}$/.test(value + "")) {
                callback(new Error("金额格式不正确"));
            } else {
                callback();
            }
        },
    };
}
