<template>
	<div class="right-menu">
		<div class="avatar-container">
			<div class="avatar-wrapper flex justify-between">
				<el-dropdown :hide-on-click="false">
					<div class="flex align-center">
						<el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" />
						<span style="
				              padding-left: 10px;
				              font-size: 18px;
				              font-weight: 600;
				              color: black;
				          ">{{ name }}</span>
					</div>
					<el-dropdown-menu slot="dropdown" style="width: 150px;">
						<el-dropdown-item>
							<div class="flex align-center" style="width: 150px">
								<img :src="logoutImg" style="width: 22px; height: 22px" />
								<a href="#" style="font-size: 18px; font-weight: 600;padding-left: 12px;" @click="logout">注销</a>
							</div>
						</el-dropdown-item>
						<el-dropdown-item>
							<div class="flex align-center" style="width: 150px">
								<img :src="xgmm" style="width: 26px; height: 26px" />
								<a href="#" style="font-size: 18px; font-weight: 600;padding-left: 10px;" @click="clickShow">修改密码</a>
							</div>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<div v-if="showBox" class="passwordBox flex justify-center align-center">
			<div class="boxCent">
				<el-row :gutter="0" style="margin-bottom: 20px;">
					<el-col :span="2">
						<div style="width: 1px;height: 1px;"></div>
					</el-col>
					<el-col :span="20">
						<div style="text-align: center;font-size: 18px;font-weight: bold;">修改密码</div>
					</el-col>
					<el-col :span="2">
						<i class="el-icon-error" style="font-size: 22px;" @click="clickHide"></i>
					</el-col>
				</el-row>
				<el-row :gutter="0">
					<el-col :span="23">
						<el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" class="allForm">
							<el-form-item label="原密码"  prop="oldPassword">
								<el-input type="password" show-password v-model="ruleForm.oldPassword" maxlength="20" show-word-limit />
							</el-form-item>
							<el-form-item label="新密码"  prop="newPassword">
								<el-input type="password" show-password v-model="ruleForm.newPassword" maxlength="20" show-word-limit />
							</el-form-item>
							<el-form-item label="确认密码"  prop="verifyPassword">
								<el-input type="password" show-password v-model="ruleForm.verifyPassword" maxlength="20" show-word-limit />
							</el-form-item>
						</el-form>
					</el-col>
				</el-row>
				<div class="confirmBtn" @click="commit">确认修改</div>
			</div>
		</div>
	</div>
</template>

<script>
	import img from "@/assets/logout.png";
	import xgmm from "@/assets/xgmm.png";
	export default {
		name: "Index",
		data() {

            var validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入新密码'));
                }else if(!/(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,16}/.test(value)
                ){
                    callback("只能输入8到16个字符，⾄少1个字母，1个数字和1个特殊字符");

                }
                else {
                    callback();
                }
            };
            var validatePass2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.ruleForm.newPassword) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
			return {
				logoutImg: img,
				xgmm: xgmm,
				name: sessionStorage.UserName,
				oldPassword: '',
				newPassword: '',
				confirmPassword: '',
				showBox: false,

                ruleForm: {
                    oldPassword: '',
                    newPassword: '',
                    verifyPassword: '',
                },

                rules: {
                    oldPassword: [
                        { required: true, message: '请输入原密码', trigger: 'change' },
                    ],
                    newPassword: [
                        // { min: 8, max: 16, message: '长度在 8 到 16 个字符', trigger: 'change' },
                        { required: true,validator: validatePass, trigger: 'change' }

                    ],
                    verifyPassword: [
                        { required: true,validator: validatePass2, trigger: 'change' }
                    ]
                }
			};
		},
		created() {},
		methods: {
			toggleSideBar() {
				this.$store.dispatch("app/toggleSideBar");
			},
			logout() {
				localStorage.clear();
				sessionStorage.clear();
				window.location.reload();
				this.$router.push({
					path: "/login"
				});
			},
			commit() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                    //
                        this.$axiosReq('/oauth/server/escort/pc/user/password', null,
                            this.ruleForm, 'put').then(res => {
                        	this.$message.success(res.msg)
                        	this.showBox = false;
                        	this.logout();
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
				// if (!this.oldPassword) {
				// 	this.$message.error('请输入原密码！')
				// 	return false;
				// } else if (!this.newPassword) {
				// 	this.$message.error('请输入新密码！')
				// 	return false;
				// } else if (!this.confirmPassword) {
				// 	this.$message.error('请输入确认密码！')
				// 	return false;
				// } else if (this.newPassword !== this.confirmPassword) {
				// 	this.$message.error('两次密码不相同！')
				// 	return false;
				// }
				// this.$axiosReq('/oauth/server/escort/pc/user/password', null, {
				// 	oldPassword: this.oldPassword,
				// 	newPassword: this.newPassword,
				// 	verifyPassword : this.confirmPassword,
				// }, 'put').then(res => {
				// 	this.$message.success(res.msg)
				// 	this.showBox = false;
				// 	this.logout();
				// })
			},
			clickShow(){
				this.showBox = true;
			},
			clickHide(){
				this.showBox = false;
			}
		},
	};
</script>

<style lang="scss" scoped>
	.navbar {
		height: 50px;
		overflow: hidden;
		position: relative;
		background: #ffffff;
		box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

		.hamburger-container {
			line-height: 46px;
			height: 100%;
			float: left;
			cursor: pointer;
			transition: background 0.3s;
			-webkit-tap-highlight-color: transparent;

			&:hover {
				background: rgba(0, 0, 0, 0.025);
			}
		}

		.breadcrumb-container {
			float: left;
		}

		.right-menu {
			float: right;
			height: 100%;
			line-height: 50px;

			&:focus {
				outline: none;
			}

			.avatar-container {
				margin-right: 30px;

				.avatar-wrapper {
					position: relative;
					font-size: 18px;
					color: #487ee0;

					.user-avatar {
						cursor: pointer;
						width: 40px;
						height: 40px;
						border-radius: 10px;
					}

					.el-icon-caret-bottom {
						cursor: pointer;
						position: absolute;
						right: -20px;
						top: 25px;
						font-size: 12px;
					}
				}
			}
		}
	}

	.passwordBox {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 999;

		.boxCent {
			width: 500px;
			// height: 300px;
			background-color: #FFFFFF;
			border-radius: 10px;
		}

		.confirmBtn{
          cursor: pointer;
			width: 150px;
			height: 35px;
			line-height: 35px;
			border-radius: 5px;
			text-align: center;
			background-color: #007AFF;
			margin: 20px auto;
			color: #FFFFFF;
		}
	}
</style>
