const src = "/course/server/escort/web/";
/**
 * 课程接口操作
 */
export const course = src + "course/";

/**
 * 课程关联用户接口操作
 */
export const courseUser = src + "courseUser/courseId/";

/**
 * (行管公司)统计查询
 */
export const inquire = src + "statistics/inquire";
/**
 * (行管公司)统计查询详情页面
 * @type {string}
 */
export const details = src + "statistics/details";

/**
 * (行管公司)课程统计
 * @type {string}
 */
export const staCourse = src + "statistics/course";

/**
 *(行管公司)课程统计详情
 * @type {string}
 */
export const staCourseDetails = src + "statistics/course/details";

/**
 * (行管公司)企业查询
 * @type {string}
 */
export const staCompany = src + "statistics/company";

/**
 * (行管公司)企业下指定课程查询详情
 * @type {string}
 */
export const staCompanyCourse = src + "statistics/company/course";

/**
 * (行管公司)企业查询详情
 * @type {string}
 */
export const staCompanyDetail = src + "statistics/company/details";

/**
 * (行管公司)学员查询
 * @type {string}
 */
export const staTrainee = src + "statistics/trainee";

/**
 * (行管公司/普通公司) 学员查询详情
 * @type {string}
 */
export const staTraineeDetails = src + "statistics/trainee/details";

/**
 * (普通企业) 统计查询
 * @type {string}
 */
export const staFirm = src + "statistics/firm";

/**
 * (普通企业) 企业下指定课程查询详情
 * @type {string}
 */
export const staFirmCourse = src + "statistics/firm/course";

/**
 * (普通企业) 学员查询
 * @type {string}
 */
export const staFirmTrainee = src + "statistics/firm/trainee";

/**
 * (行管公司)统计查询导出
 */
export const inquireE = src + "statistics/inquire/export";
/**
 * (行管公司)统计查询详情页面导出
 * @type {string}
 */
export const detailsE = src + "statistics/details/export";

/**
 * (行管公司)课程统计导出
 * @type {string}
 */
export const staCourseE = src + "statistics/course/export";

/**
 *(行管公司)课程统计详情导出
 * @type {string}
 */
export const staCourseDetailsE = src + "statistics/course/details/export";

/**
 * (行管公司)企业查询导出
 * @type {string}
 */
export const staCompanyE = src + "statistics/company/export";

/**
 * (行管公司)企业下指定课程查询详情导出
 * @type {string}
 */
export const staCompanyCourseE = src + "statistics/company/course/export";

/**
 * (行管公司)企业查询详情导出
 * @type {string}
 */
export const staCompanyDetailE = src + "statistics/company/details/export";

/**
 * (行管公司)学员查询导出
 * @type {string}
 */
export const staTraineeE = src + "statistics/trainee/export";

/**
 * (行管公司/普通公司) 学员查询详情导出
 * @type {string}
 */
export const staTraineeDetailsE = src + "statistics/trainee/details/export";

/**
 * (普通企业) 统计查询导出
 * @type {string}
 */
export const staFirmE = src + "statistics/firm/export";

/**
 * (普通企业) 企业下指定课程查询详情导出
 * @type {string}
 */
export const staFirmCourseE = src + "statistics/firm/export/courseId/";

/**
 * (普通企业) 学员查询导出
 * @type {string}
 */
export const staFirmTraineeE = src + "statistics/firm/trainee/export";

/**
 * 省市区
 * @type {string}
 */
export const areaTreeAll = "/data/server/escort/web/area/tree";
/**
 * 通过人员id查看课程记录
 * @type {string}
 */
export const courseUserDetails = src + "courseUser/details";

/**
 * 纠偏学习列表
 * @type {string}
 */
export const activeDefense = "/car/server/escort/web/active/defense";

/**
 * 纠偏学习违规下来框
 * @type {string}
 */
export const warnTypeSelect = "/car/server/escort/web/warnType/select";

/**
 * 纠偏学习导出
 * @type {string}
 */
export const activeDefenseExport =
    "/car/server/escort/web/active/defense/Export";

/**
 * 培训课件列表
 * @type {string}
 */
export const courseSelf = src + "course/self";

/**
 *
 * @type {string}
 */
export const courseDetailsId = src + "course/details/";
export const courseDetails = src + "course/details/";

export function queryVideo() {}

export function querySubject() {}

export const getCourseByCompanyId = () => {};

export const determineTrainingHours = () => {};

export const addCourseMsg = () => {};

export const RollBack = () => {};

export const DetCoursemsg = () => {};

export const CourseMsg = () => {};

export const getMsgByCourseId = () => {};
