const src = '/data/server/escort/web/'

/**
 * 根据id查找子集字典
 */
export const getDictChildren = src + 'dict/children' // {id}

/**
 * 通过父编码获取子编码
 */
export const getDictParentCode = src + 'dict/parentCode/' // {id}

/**
 * 数据字典操作
 */
export const dict = src + 'dict/'

/**
 * 列表查询树型菜单
 */
export const getDictTree = src + 'dict/tree/'



/**
 * 通过父编码与子编码查询
 */
export function getDictTreePC(pcdoe,ccode) {
  return `'dict/arentCode/${code}/code/${ccode}'`
}
