export default [
    {
        path: "/carTechnologyManage/carRecordManage",
        meta: { title: "车辆档案管理" },
        component: () =>
            import(
                /* webpackChunkName: "carRecordManage" */ "../pages/carTechnologyManage/carRecordManage/HomeView.vue"
            ),
    },
    {
        path: "/carTechnologyManage/energyManage",
        meta: { title: "能耗管理" },
        component: () =>
            import(
                /* webpackChunkName: "energyManage" */ "../pages/carTechnologyManage/energyManage/HomeView.vue"
            ),
    },
    {
        path: "/carTechnologyManage/repairManage",
        meta: { title: "维修管理" },
        component: () =>
            import(
                /* webpackChunkName: "repairManage" */ "../pages/carTechnologyManage/repairManage/HomeView.vue"
            ),
    },
    {
        path: "/carTechnologyManage/policyManage",
        meta: { title: "保单管理" },
        component: () =>
            import(
                /* webpackChunkName: "policyManage" */ "../pages/carTechnologyManage/policyManage/HomeView.vue"
            ),
    },
    {
        path: "/carTechnologyManage/importantPartsManage",
        meta: { title: "重要零件管理" },
        component: () =>
            import(
                /* webpackChunkName: "importantPartsManage" */ "../pages/carTechnologyManage/importantPartsManage/HomeView.vue"
            ),
    },
    {
        path: "/carTechnologyManage/carAccidentManage",
        meta: { title: "车辆事故管理" },
        component: () =>
            import(
                /* webpackChunkName: "carAccidentManage" */ "../pages/carTechnologyManage/carAccidentManage/HomeView.vue"
            ),
    },
];
