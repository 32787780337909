<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  render(h, context) {
    const { icon, title } = context.props
    const vnodes = []
    if (icon) {
      // 菜单图标，分为ICON,SVG,PNG三种，看ICON类型名称，去渲染用哪种
      if (icon.includes('el-icon')) { // ICON格式
        vnodes.push(<i class={[icon, 'sub-el-icon']} />)
      } else if (icon.includes('.png')) { // PNG格式
        const path = require('@/assets/menu/' + icon)
        vnodes.push(<img src={ path } />)
      } else { // svg被特殊处理过，icon只要是svg的名称，无需后缀
        vnodes.push(<svg-icon icon-class={icon}/>)
      }
    }

    if (title) {
      vnodes.push(<span slot='title'>{(title)}</span>)
    }
    return vnodes
  }
}
</script>

<style scoped>
.sub-el-icon {
  color: currentColor;
  width: 1em;
  height: 1em;
}
</style>
