<template>
    <el-select
        filterable
        v-bind="$attrs"
        v-on="$listeners"
        clearable
        placeholder="请选择车辆线路"
    >
        <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.lineName"
            :value="item.id"
        >
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: "GlobalLineSelect",
    props: {
        companyId: { type: String, default: "" },
    },
    data() {
        return {
            options: [],
            value: "",
        };
    },
    watch: {
        companyId: {
            handler() {
                if (this.companyId) {
                    this.fetchData();
                } else {
                    this.options = [];
                }
            },
            deep: true,
        },
    },
    methods: {
        async fetchData() {
            let { code, data } = await this.$api.get(
                `/car/server/escort/web/line?companyId=${this.companyId}&currentPage=1&pageSize=10000`
            );
            if (code == 200) {
                this.options = data.content;
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
