<template>
    <div>
        <div v-for="(route, i) in menu" :key="i">
            <template v-if="!route.alwaysShow">
                <el-submenu :key="route.path" :index="route.path">
                    <template slot="title">
                        <i class="el-icon-location" />
                        <span slot="title">{{ route.meta.title }}</span>
                    </template>
                    <el-menu-item
                        v-for="child in route.children"
                        :key="child.path"
                        :index="getRoutePath(route, child)"
                        >{{ child.meta.title }}</el-menu-item
                    >
                </el-submenu>
            </template>
            <template v-else>
                <el-menu-item
                    v-for="child in route.children"
                    :key="child.path"
                    :index="getRoutePath(route, child)"
                >
                    <i class="el-icon-document" />
                    <span slot="title">{{ child.meta.title }}</span>
                </el-menu-item>
            </template>
        </div>
    </div>
</template>

<script>
import path from "path";
import { isExternal } from "@/utils/validated";
import Item from "./Item";
import AppLink from "./Link";
import FixiOSBug from "./FixiOSBug";

export default {
    name: "ZyfBarItem",
    components: { Item, AppLink },
    mixins: [FixiOSBug],
    props: {
        // route object
        item: {
            type: Object,
            required: true,
        },
        isNest: {
            type: Boolean,
            default: false,
        },
        basePath: {
            type: String,
            default: "",
        },
        menu: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    data() {
        // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
        // TODO: refactor with render function
        this.onlyOneChild = null;
        return {};
    },
    methods: {
        hasOneShowingChild(children = [], parent) {
            const showingChildren = children.filter((item) => {
                if (item.hidden) {
                    return false;
                } else {
                    // Temp set(will be used if only has one showing child)
                    this.onlyOneChild = item;
                    return true;
                }
            });

            // When there is only one child router, the child router is displayed by default
            if (showingChildren.length === 1) {
                return true;
            }

            // Show parent if there are no child router to display
            if (showingChildren.length === 0) {
                this.onlyOneChild = {
                    ...parent,
                    path: "",
                    noShowingChildren: true,
                };
                return true;
            }

            return false;
        },
        resolvePath(routePath) {
            if (isExternal(routePath)) {
                return routePath;
            }
            if (isExternal(this.basePath)) {
                return this.basePath;
            }
            return path.resolve(this.basePath, routePath);
        },
        getRoutePath(p, c) {
            if (p.path === "/") {
                return p.path + c.path;
            } else {
                return p.path + "/" + c.path;
            }
        },
    },
};
</script>

<style scoped></style>
