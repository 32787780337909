module.exports = {
    // title: "悟空护驾",
    title: "智达安教平台",

    /**
     * 是否拥有标签栏
     * @type {boolean} true | false
     */
    tagsView: true,

    /**
     * 是否固定面包屑栏
     * @type {boolean} true | false
     */
    fixedHeader: true,

    /**
     * 是否拥有LOG栏，最顶层
     * @type {boolean} true | false
     */
    sidebarLogo: true,

    /**
     * 是否拥有头部栏，最顶层
     * @type {boolean} true | false
     */
    hasHeaderBar: false,

    /**
     * 是否拥有左侧菜单栏，最顶层
     * @type {boolean} true | false
     */
    hasSideBar: true,

    /**
     * 是否拥有面包屑栏，最顶层
     * @type {boolean} true | false
     */
    hasBreadBar: true,

    /**
     * 是否有菜单栏上用户信息
     * @type {boolean} true | false
     */
    hasUserInfo: true,

    /**
     * 菜单栏所在位置
     * @type {String} 'left' | 'top'
     */
    menuMode: "left",

    /**
     * 是否是动态菜单，如果为true，则router/index.js写的静态路由不会生效，如果为false,将只会读取静态路由
     * @type {boolean} true | false
     */
    isDyMenu: true,
};
