<template>
    <el-menu
        :default-active="$route.path"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="true"
        router
        :mode="mode"
    >
        <div v-for="(itemOne, indexOne) in getMenus" :key="indexOne">
            <el-submenu v-if="itemOne.sons.length != 0" :index="itemOne.route">
                <template slot="title">
                    <i class="iconfont" :class="itemOne.icon"></i>
                    <span>{{ itemOne.permissionsName }}</span>
                </template>
                <el-menu-item
                    v-for="(item, index) in itemOne.sons"
                    :index="item.fullRoute"
                    :key="index"
                >
                    <span slot="title">{{ item.permissionsName }}</span>
                </el-menu-item>
            </el-submenu>
            <el-menu-item v-else :index="itemOne.route">
                <i class="iconfont" :class="itemOne.icon"></i>
                <span slot="title">{{ itemOne.permissionsName }}</span>
            </el-menu-item>
        </div>
    </el-menu>
</template>

<script>
import bus from "@/bus/index.js";
import { mapGetters } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import ZyfBarItem from "./ZyfBarItem";
import variables from "@/styles/variables.scss";

export default {
    // eslint-disable-next-line vue/no-unused-components
    // components: { SidebarItem, Logo, ZyfBarItem },
    props: {
        mode: {
            type: String,
            default: "vertical",
        },
    },
    data() {
        return {
            menu: "",
            getMenus: [],
            // menu: this.$router.options.routes
        };
    },
    computed: {
        ...mapGetters(["sidebar", "isDyMenu"]),
        activeMenu() {
            const route = this.$route;
            const { meta, path } = route;
            // if set path, the sidebar will highlight the path you set
            if (meta.activeMenu) {
                return meta.activeMenu;
            }
            return path;
        },
        showLogo() {
            return this.$store.state.settings.sidebarLogo;
        },
        variables() {
            return variables;
        },
        isCollapse() {
            return !this.sidebar.opened;
        },
    },
    created() {
        if (this.isDyMenu) {
            this.menu = sessionStorage.menu
                ? JSON.parse(sessionStorage.menu)
                : [];
        } else {
            this.menu = this.$router.options.routes;
        }
        this.menu = this.menu.filter((v) => {
            return !v.hidden;
        });
        // //console.log(this.menu);
        console.log(bus.getMenus());

        this.getMenus = bus.getMenus();
    },
    methods: {
        getRoutePath(p, c) {
            // debugger;
            // //console.log(p.path, c.path);
            if (p.path === "/") {
                return p.path + c.path;
            } else {
                return p.path + "/" + c.path;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.iconfont {
    padding-right: 5px;
}
::v-deep.el-menu--collapse {
    .iconfont {
        display: inline-flex;
        color: #fff !important;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding-left: 20px;
    }
    .icon-shouye {
        padding-left: 0 !important;
        color: #fff !important;
    }
}
.el-menu-item{
    .icon-shouye {
        padding-left: 0 !important;
        color: #fff !important;
    }
}
</style>
