var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 h-100",class:_vm.classObj},[(_vm.hasHeaderBar)?_c('HeadBar',{scopedSlots:_vm._u([(_vm.menuMode === 'top')?{key:"sideBar",fn:function(){return [_c('sidebar',{staticClass:"sidebar-inner hor-sidebar",attrs:{"mode":"horizontal"}})]},proxy:true}:null,{key:"avatar",fn:function(){return [_c('div')]},proxy:true}],null,true)}):_vm._e(),(_vm.hasSideBar && _vm.menuMode === 'left')?_c('div',{style:({
            height: ("calc(100% -  " + _vm.headBarHeight + ")"),
            top: _vm.headBarHeight,
            width: _vm.sideBarWidth,
        }),attrs:{"id":"sideBar"}},[_c('UserInfo'),_c('sidebar',{staticClass:"sidebar-inner",style:({
                height: ("calc(100% -  " + _vm.headBarHeight + " - " + _vm.userInfoHeight + ")"),
                overflow: 'auto',
            }),attrs:{"mode":"vertical"}})],1):_vm._e(),_c('div',{staticClass:"w-100",style:({
            height: ("calc(100% -  " + _vm.headBarHeight + ")"),
            top: _vm.headBarHeight,
            width: ("calc(100% - " + _vm.sideBarWidth + ")"),
            left: _vm.sideBarWidth,
        }),attrs:{"id":"main-container"}},[(_vm.hasBreadBar)?_c('div',{class:{ 'fixed-header': _vm.fixedHeader }},[_c('navbar')],1):_vm._e(),_c('div',{style:({
                height: ("calc(100% -  " + _vm.navBarHeight + " - " + _vm.tagsHeight + ")"),
            })},[_c('router-view')],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }