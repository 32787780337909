import request from '@/utils/request'

// 获取新闻
export function getAllPress(param) {
  return request({
    url: 'press/getAllPress',
    method: 'get',
    params: param
  })
}
// 添加新闻
export function addPress(data, param) {
  return request({
    url: 'press/addPress',
    method: 'post',
    data,
    params: param
  })
}
// 删除新闻
export function detPress(data, param) {
  return request({
    url: 'press/detPress',
    method: 'delete',
    data,
    params: param
  })
}

// 更新新闻
export function updPress(data, param) {
  return request({
    url: 'press/updPress',
    method: 'post',
    data,
    params: param
  })
}

// 发布新闻
export function releasePress(data, param) {
  return request({
    url: 'press/releasePress',
    method: 'post',
    data,
    params: param
  })
}


const src = '/info/server/escort/web/'
/**
 * 企业接口操作
 */
export const journalism = src + 'journalism/'
/**
 * 企业列表-启用停用
 */
export const journalismRelease = src + 'journalism/release/' // {id}
