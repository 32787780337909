const src = '/course/server/escort/web/'

export const topic = src + 'topic/' // {id}
export const topicVideo = src + 'topic/videoId/' // {id}

export function updateSubject() {}

export function getSubjectOption() {}

export function getSubject() {}

export const det = () => {}

export const changeStatus = () => {}

export const addS = () => {}

export const addSS = () => {}
