import { login } from "@/api";
import router from "@/router";
import { convertTreeNoComponent, menuFilterNoComponent } from "@/utils/menu";
import { setSessionStorage } from "@/utils/storage";
import { flatData } from "@/utils/handleJson";
import { axiosReq } from "@/utils/request";
import { transData } from "@/utils/handleJson";

import bus from "@/bus/index.js";

const state = {
    Authorization: "",
    name: "",
    loginInfo: "",
    master: "",
    main: 0,
};

const mutations = {
    TOGGLE_MAIN: (state, status) => {
        state.main = status;
    },
};
const actions = {
    // 登录
    login({ commit }, info) {
        return new Promise((resolve, reject) => {
            axiosReq(login, info, null, "post")
                .then((res) => {
                    const data = res.data;

                    // console.log(data.userDetail.sysPermissionsVos);
                    bus.saveMenus(data.userDetail.sysPermissionsVos);
                    sessionStorage.setItem("Authorization", data.access_token);
                    sessionStorage.setItem(
                        "UserName",
                        data.userDetail.username
                    );
                    sessionStorage.setItem(
                        "UserDetail",
                        JSON.stringify(data.userDetail)
                    );
                    sessionStorage.setItem(
                        "CompanyType",
                        data.userDetail.sysCompanyVo.companyType
                    );
                    sessionStorage.setItem(
                        "RegionCode",
                        data.userDetail.sysCompanyVo.regionCode
                    );
                    sessionStorage.setItem(
                        "ProvinceCode",
                        data.userDetail.sysCompanyVo.provinceCode
                    );
                    sessionStorage.setItem(
                        "CompanyId",
                        data.userDetail.sysCompanyVo.id
                    );
                    sessionStorage.setItem(
                        "CompanyIds",
                        JSON.stringify(data.userDetail.companyIds)
                    );
                    sessionStorage.setItem("Level", data.userDetail.level);
                    sessionStorage.setItem(
                        "SonIds",
                        data.userDetail.companyIds.length > 1
                    );
                    sessionStorage.setItem(
                        "Admin",
                        data.userDetail.roles.includes("superAdmin")
                    );
                    sessionStorage.setItem(
                        "CompanyName",
                        data.userDetail.sysCompanyVo.companyName
                    );
                    sessionStorage.setItem(
                        "logoPath",
                        data.userDetail.sysCompanyVo.logoUrl
                    );
                    sessionStorage.setItem(
                        "iconPath",
                        data.userDetail.sysCompanyVo.iconUrl
                    );

                    // const menu = data.userDetail.sysPermissionsVos;
                    // const menuRouters = [];
                    // const menuInfo = {
                    //     id: "id",
                    //     pId: "parentId",
                    //     path: "route",
                    //     title: "permissionsName",
                    //     fullPath: "fullRoute",
                    //     icon: "icon",
                    //     noCache: true,
                    //     breadcrumb: true,
                    //     affix: false,
                    //     alwaysShow: false,
                    // };
                    // const mainPath = "/supervise";
                    // const menuData = flatData(menu, "sons");
                    // convertTreeNoComponent(
                    //     menuData,
                    //     menuRouters,
                    //     menuInfo,
                    //     mainPath
                    // );
                    // const list = transData(
                    //     menuRouters,
                    //     "id",
                    //     "pId",
                    //     "children"
                    // );
                    // menuFilterNoComponent(list, mainPath);
                    // setSessionStorage("menu", list);
                    // router.addRoutes(list);

                    if (
                        Array.isArray(res.data.userDetail.sysPermissionsVos) &&
                        res.data.userDetail.sysPermissionsVos.length === 0
                    ) {
                        router.push("/403");
                    } else {
                        let obj = res.data.userDetail.sysPermissionsVos[0];
                        // console.log(obj);
                        // router.push("/");
                        if (obj) {
                            if (
                                Array.isArray(obj.sons) &&
                                obj.sons.length > 0
                            ) {
                                router.push(obj.sons[0].fullRoute);
                            } else {
                                router.push(obj.fullRoute);
                            }
                        } else {
                            router.push("/");
                        }
                    }
                    // router.push(list[0].redirect);
                    resolve(res);
                })
                .finally((res) => {
                    reject(res);
                });
        });
    },
    toggleMain({ commit }, status) {
        commit("TOGGLE_MAIN", status);
    },

    loginAuto({ commit }, info) {
        const data = info.data;
        console.log(data);
        // //console.log(data.userDetail.sysPermissionsVos);
        bus.saveMenus(data.sysPermissionsVos);
        // sessionStorage.setItem("Authorization", data.access_token);
        sessionStorage.setItem("UserName", data.username);
        sessionStorage.setItem("UserDetail", JSON.stringify(data));
        sessionStorage.setItem("CompanyType", data.sysCompanyVo.companyType);
        sessionStorage.setItem("RegionCode", data.sysCompanyVo.regionCode);
        sessionStorage.setItem("ProvinceCode", data.sysCompanyVo.provinceCode);
        sessionStorage.setItem("CompanyId", data.sysCompanyVo.id);
        sessionStorage.setItem("CompanyIds", JSON.stringify(data.companyIds));
        sessionStorage.setItem("Level", data.level);
        sessionStorage.setItem("SonIds", data.companyIds.length > 1);
        sessionStorage.setItem("Admin", data.roles.includes("superAdmin"));
        router.push("/");
        // const menu = data.userDetail.sysPermissionsVos;
        // const menuRouters = [];
        // const menuInfo = {
        //     id: "id",
        //     pId: "parentId",
        //     path: "route",
        //     title: "permissionsName",
        //     fullPath: "fullRoute",
        //     icon: "icon",
        //     noCache: true,
        //     breadcrumb: true,
        //     affix: false,
        //     alwaysShow: false,
        // };
        // const mainPath = "/supervise";
        // const menuData = flatData(menu, "sons");
        // convertTreeNoComponent(
        //     menuData,
        //     menuRouters,
        //     menuInfo,
        //     mainPath
        // );
        // const list = transData(
        //     menuRouters,
        //     "id",
        //     "pId",
        //     "children"
        // );
        // menuFilterNoComponent(list, mainPath);
        // setSessionStorage("menu", list);
        // router.addRoutes(list);

        // router.push(list[0].redirect);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
