export default [
    {
        path: "/organize/company",
        meta: { title: "企业管理" },
        component: () =>
            import(
                /* webpackChunkName: "company" */ "../pages/organize/company/index.vue"
            ),
    },
    {
        path: "/organize/dept",
        meta: { title: "部门管理" },
        component: () =>
            import(
                /* webpackChunkName: "dept" */ "../pages/organize/dept/index.vue"
            ),
    },
    {
        path: "/organize/jobs",
        meta: { title: "岗位管理" },
        component: () =>
            import(
                /* webpackChunkName: "jobs" */ "../pages/organize/jobs/index.vue"
            ),
    },
    {
        path: "/organize/persion",
        meta: { title: "个人档案" },
        component: () =>
            import(
                /* webpackChunkName: "person" */ "../pages/organize/persion/index.vue"
            ),
    },
    {
        path: "/organize/role",
        meta: { title: "角色管理" },
        component: () =>
            import(
                /* webpackChunkName: "role" */ "../pages/organize/role/index.vue"
            ),
    },
    {
        path: "/organize/permission",
        meta: { title: "web权限管理" },
        component: () =>
            import(
                /* webpackChunkName: "permission" */ "../pages/organize/permission/index.vue"
            ),
    },
    {
        path: "/organize/permissionApp",
        meta: { title: "app权限管理" },
        component: () =>
            import(
                /* webpackChunkName: "permissionApp" */ "../pages/organize/permissionApp/index.vue"
            ),
    },
    {
        path: "/organize/manageCar",
        meta: { title: "车辆档案管理" },
        component: () =>
            import(
                /* webpackChunkName: "manageCar" */ "../pages/organize/manageCar/index.vue"
            ),
    },
    {
        path: "/organize/archives",
        meta: { title: "档案登记" },
        component: () =>
            import(
                /* webpackChunkName: "archives" */ "../pages/organize/archives/index.vue"
            ),
    },
    {
        path: "/organize/lineManagement",
        meta: { title: "线路管理" },
        component: () =>
            import(
                /* webpackChunkName: "lineManagement" */ "../pages/organize/lineManagement/index.vue"
            ),
    },
    {
        path: "/organize/equipment",
        meta: { title: "设备管理" },
        component: () =>
            import(
                /* webpackChunkName: "equipment" */ "../pages/organize/equipment/index.vue"
            ),
    },
    {
        path: "/organize/tagManagement",
        meta: { title: "标签管理" },
        component: () =>
            import(
                /* webpackChunkName: "tagManagement" */ "../pages/organize/tagManagement/index.vue"
            ),
    },
    {
        path: "/organize/banner",
        meta: { title: "Banner管理" },
        component: () =>
            import(
                /* webpackChunkName: "banner" */ "../pages/organize/banner/index.vue"
            ),
    },
];
