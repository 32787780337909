import request from '@/utils/request'
// const src = 'dept'
// 查询
export function getDeptByCompany(data) {
  return request({
    url: src + '/getDeptByCompany',
    method: 'get',
    params: data
  })
}

// 增加
export function addDept(data, param) {
  return request({
    url: src + '/addDept',
    method: 'post',
    data,
    params: param
  })
}

// 更新
export function reName(data, param) {
  return request({
    url: src + '/reName',
    method: 'PUT',
    data,
    params: param
  })
}

// 删除
export function detDept(data, param) {
  return request({
    url: src + '/detDept',
    method: 'DELETE',
    data,
    params: param
  })
}

// 增加用户部门关系
export function addUserOfDept(data, param) {
  return request({
    url: src + '/addUserOfDept',
    method: 'POST',
    data,
    params: param
  })
}

// 更新用户部门关系
export function updUserOfDept(data, param) {
  return request({
    url: src + '/updUserOfDept',
    method: 'PUT',
    data,
    params: param
  })
}

// 删除用户部门关系
export function detUserOfDept(data, param) {
  return request({
    url: src + '/detUserOfDept',
    method: 'DELETE',
    data,
    params: param
  })
}

// 获取部门下所有人员
export function getMasterByCom(param) {
  return request({
    url: src + '/getMasterByCom',
    method: 'GET',
    params: param
  })
}

const src = '/oauth/server/escort/web/'
/**
 * 部门接口操作
 */
export const depart = src + 'depart' // {id}

