const src = '/oauth/server/escort/web/'
/**
 * 人员操作
 * @type {string}
 */
export const user = src + 'user/' // {id}
/**
 * 人员-离职
 * @type {string}
 */
export const userLeave = src + 'user/leave/' // {id}
/**
 * 人员-角色
 * @type {string}
 */
export const userRole = src + 'user/role/' // {id}
/**
 * 人员-状态
 * @type {string}
 */
export const userStatus = src + 'user/status/' // {id}

/**
 * 人员-基础信息
 * @type {string}
 */
export const userMsgBasic = src + 'userMsg/basic/' // {id}

/**
 * 通过公司ID查询公司的管理员、行管账号
 * @type {string}
 */
export const userCompany = src + 'userMsg/company/' // {id}

/**
 * 人员-修改验证身份证号
 * @type {string}
 */
export const userVerifyCard = src + 'userVerify/card/' // {id}
/**
 * 人员-修改验证邮箱
 * @type {string}
 */
export const userVerifyEmail = src + 'userVerify/email/' // {id}
/**
 * 人员-修改验证手机
 * @type {string}
 */
export const userVerifyPhone = src + 'userVerify/phone/' // {id}

/**
 * 通过课程ID查询对应的人员
 * @type {string}
 */
export const sysUserCourseId = src + 'sysUser/courseId/' // {id}
/**
 * 通过公告ID查询对应的人员
 * @type {string}
 */
export const sysUserNoticeId = src + 'sysUser/noticeId/' // {id}
/**
 * 重置密码
 * @type {string}
 */
export const sysUserResetPwd = src + 'sysUser/resetPwd/' // {id}
/**
 * 批量删除
 * @type {string}
 */
export const deleteAll = src + 'user/batch'
/**
 * 重置人脸
 * @type {string}
 */
export const resetHead = src + 'sysUser/resetHead/'

/**
 * 重置人脸
 * @type {string}
 */
export const downloadTemplate = src + 'user/batch/template'

/**
 * 批量导入人员
 * @type {string}
 */
export const uesrBatch = src + 'user/batch'

export function selectIdentityByCompanyId() {}
