<template>
    <el-select
        v-bind="$attrs"
        clearable
        v-on="$listeners"
        :placeholder="placeholder"
    >
        <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.dictName"
            :value="item.dictCode"
        >
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: "GlobalDictSelect",
    props: {
        placeholder: { type: String, default: "请选择" },
        parentCode: { type: String, required: true },
    },
    data() {
        return {
            options: [],
            value: "",
        };
    },
    async created() {
        let { code, data } = await this.$api.get(
            "/data/server/escort/web/dict/parentCode/" + this.parentCode
        );
        if (code == 200) {
            this.options = data;
        }
    },
};
</script>

<style lang="scss" scoped></style>
