import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes.js";
import Home from "../layout/index.vue";
// import { router_mode } from "../env/index.js";
Vue.use(VueRouter);

const router = new VueRouter({
    // mode: "history",
    mode: "hash",
    // mode: router_mode,
    // mode: 'history', // require service support
    // scrollBehavior: () => ({ y: 0 }),
    routes: [
        // {
        //   path: '/login',
        //   component: () => import('@/views/login/index'),

        // },
        {
            path: "/login",
            name: "Login",
            meta: {
                title: "登陆",
            },
            hidden: true,
            component: () =>
                import(/* webpackChunkName: "login" */ "@/views/login/index"),
        },
        {
            path: "/",
            name: "Home",
            redirect: "/supervise",
            hidden: true,
            component: Home,
            // () =>
            // import(/* webpackChunkName: "home" */ "../layout/index.vue"),
            children: routes,
        },
        {
            path: "/403",
            component: () => import("@/views/Error403.vue"),
            hidden: true,
        },
        {
            path: "/404",
            component: () => import("@/views/404.vue"),
            hidden: true,
        },
    ],
});

export default router;
