const src = '/oauth/server/escort/web/'
/**
 * 企业接口操作
 */
export const company = src + 'company'
/**
 * 企业列表-配置权限
 */
export const companyPermission = src + 'company/permission/' // {id}

/**
 * 企业列表-配置岗位
 */
export const companyPosition = src + 'company/position/' // {id}
/**
 * 企业列表-配置角色
 */
export const companyRole = src + 'company/role/' // {id}
/**
 * 企业列表-启用停用
 */
export const companyStatus = src + 'company/status/'

/**
 * 通过课程ID获取对应的企业下拉框
 */
export const companyCourseId = src + 'company/courseId/'

/**
 * 通过区域code获取对应的企业
 */
export const companyAll = src + 'company/areaCode'

export const getCompanyBySelect = () => {}

export const getCompanyByRegion = () => {}

export const getAllCompanies = () => {}
