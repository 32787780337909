const src = '/course/server/escort/web/'

export const video = src + 'videoInfo/' // {id}
export const videoCourse = src + 'videoInfo/courseId/' // {id}

export function updateVideo() {}

export function detVideo() {}

export const allVideo = () => {}

export const addVideo = () => {}
