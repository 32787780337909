export default [
    {
        path: "/knowledgeManage/knowledgeList",
        meta: { title: "知识库列表" },
        component: () =>
            import(
                /* webpackChunkName: "knowledgeList" */ "../pages/knowledgeManage/knowledgeList/HomeView.vue"
            ),
    },
];
