const src = '/data/server/escort/web/'
// const src = '/escort/web/'
/**
 * 获得所有地区
 */
export const area = src + 'area/'

/**
 * 根据父id查询子集集合
 */
export const getAreaChildren = src + 'area/children/'

/**
 * 根据父code查询子集
 */
export const areaCode = src + 'area/code/'

/**
 * 根据code验证行政区域
 */
export const areaCodes = src + 'area/codes/'

/**
 * 根据id验证行政区域
 */
export const areaIds = src + 'area/ids/'

/**
 * 根据id查询所有父级
 */
export const areaParent = src + 'area/parent/'
/**
 *
 根据父code查询子集集合
 */
export const areaParentCode = src + 'area/parentCode/'

/**
 * 获得所有地区树级
 */
export const getAreaTree = src + 'area/tree/'

/**
 * 获得所有地区树级
 */
export const getAreaTreeByCode = src + 'area/tree/'

export function updateRegion() {}

export const getAllRegion = () => {}

export const getAllDistrict = () => {}

export const addRegion = () => {}
