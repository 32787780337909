const src = "/oauth/server/escort/web/";
/**
 * 获取验证码,特殊的不要前缀
 */
export const getLoginCode = "/code";
/**
 * WEB登录
 */
export const login = src + "login";
/**
 * WEB登出
 */
export const logout = src + "logout";
