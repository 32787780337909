const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  hasUserInfo: state => state.settings.hasUserInfo,
  sidebarLogo: state => state.settings.sidebarLogo,
  fixedHeader: state => state.settings.fixedHeader,
  tagsView: state => state.settings.tagsView,
  hasHeaderBar: state => state.settings.hasHeaderBar,
  hasSideBar: state => state.settings.hasSideBar,
  hasBreadBar: state => state.settings.hasBreadBar,
  headBarHeight: state => state.settings.headBarHeight,
  tagsHeight: state => state.settings.tagsHeight,
  navBarHeight: state => state.settings.navBarHeight,
  sideBarWidth: state => state.settings.sideBarWidth,
  userInfoHeight: state => state.settings.userInfoHeight,
  menuMode: state => state.settings.menuMode,
  isDyMenu: state => state.settings.isDyMenu,
  main: state => state.master.main
}
export default getters
