<template>
  <el-dialog
    :visible.sync="show"
    width="40%"
    :append-to-body="false"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div slot="title" class="header-title text-center">
      <span class="title-name">修改样式</span>
    </div>
    <el-row :gutter="0">
      <el-col :span="24">

      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer" style="text-align: center;">
      <el-button class="text-white btn-back-primary" size="mini" @click="commit">确认</el-button>
      <el-button class="text-white btn-back-close" size="mini" @click="close">取消</el-button>
    </div>
  </el-dialog>
<!--  <div class="drawer-container">-->
<!--    <div>-->
<!--      <h3 class="drawer-title">Page style setting</h3>-->

<!--      <div class="drawer-item">-->
<!--        <span>Open Tags-View</span>-->
<!--        <el-switch v-model="tagsView" class="drawer-switch" />-->
<!--      </div>-->

<!--      <div class="drawer-item">-->
<!--        <span>Fixed Header</span>-->
<!--        <el-switch v-model="fixedHeader" class="drawer-switch" />-->
<!--      </div>-->

<!--      <div class="drawer-item">-->
<!--        <span>Sidebar Logo</span>-->
<!--        <el-switch v-model="sidebarLogo" class="drawer-switch" />-->
<!--      </div>-->

<!--    </div>-->
<!--  </div>-->
</template>

<script>

export default {
  components: { },
  props: {
    // 打开dialog的
    dialog: {
      type: Boolean, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: false // 这样可以指定默认的值
    }
  },
  data() {
    return {}
  },
  computed: {
    fixedHeader: {
      get() {
        return this.$store.state.settings.fixedHeader
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'fixedHeader',
          value: val
        })
      }
    },
    tagsView: {
      get() {
        return this.$store.state.settings.tagsView
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'tagsView',
          value: val
        })
      }
    },
    sidebarLogo: {
      get() {
        return this.$store.state.settings.sidebarLogo
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'sidebarLogo',
          value: val
        })
      }
    }
  },
  methods: {
    themeChange(val) {
      this.$store.dispatch('settings/changeSetting', {
        key: 'theme',
        value: val
      })
    },
    commit() {

    },
    // 关闭模态框
    close() {
      this.show = false
      this.$emit('refresh')
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer-container {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;

  .drawer-title {
    margin-bottom: 12px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 22px;
  }

  .drawer-item {
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    padding: 12px 0;
  }

  .drawer-switch {
    float: right
  }
}
</style>
