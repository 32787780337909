import Vue from "vue";

import GlobalElDatePicker from "./GlobalElDatePicker.vue";
Vue.component("GlobalElDatePicker", GlobalElDatePicker);

import GlobalDictSelect from "./GlobalDictSelect.vue";
Vue.component("GlobalDictSelect", GlobalDictSelect);

import GlobalCompanySelect from "./GlobalCompanySelect.vue";
Vue.component("GlobalCompanySelect", GlobalCompanySelect);

import GlobalLineSelect from "./GlobalLineSelect.vue";
Vue.component("GlobalLineSelect", GlobalLineSelect);

import GlobalRichText from "./GlobalRichText.vue";
Vue.component("GlobalRichText", GlobalRichText);

import GlobalElUpload from "./GlobalElUpload.vue";
Vue.component("GlobalElUpload", GlobalElUpload);

import GlobalCarSelect from "./GlobalCarSelect.vue";
Vue.component("GlobalCarSelect", GlobalCarSelect);

import GlobalBatchExport from "./GlobalBatchExport.vue";
Vue.component("GlobalBatchExport", GlobalBatchExport);

import GlobalElFileUpload from "./GlobalElFileUpload.vue";
Vue.component("GlobalElFileUpload", GlobalElFileUpload);
