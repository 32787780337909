<template>
    <div class="w-100 h-100" :class="classObj">
        <HeadBar v-if="hasHeaderBar">
            <template v-if="menuMode === 'top'" v-slot:sideBar>
                <sidebar class="sidebar-inner hor-sidebar" mode="horizontal" />
            </template>
            <template v-slot:avatar>
                <!--占位，方便布局，如果需要也可以同时打开头像组件-->
                <div />
                <!--    <AvatarBar />-->
            </template>
        </HeadBar>
        <div
            v-if="hasSideBar && menuMode === 'left'"
            id="sideBar"
            :style="{
                height: `calc(100% -  ${headBarHeight})`,
                top: headBarHeight,
                width: sideBarWidth,
            }"
        >
            <UserInfo />
            <sidebar
                class="sidebar-inner"
                mode="vertical"
                :style="{
                    height: `calc(100% -  ${headBarHeight} - ${userInfoHeight})`,
                    overflow: 'auto',
                }"
            />
        </div>
        <div
            id="main-container"
            class="w-100"
            :style="{
                height: `calc(100% -  ${headBarHeight})`,
                top: headBarHeight,
                width: `calc(100% - ${sideBarWidth})`,
                left: sideBarWidth,
            }"
        >
            <div v-if="hasBreadBar" :class="{ 'fixed-header': fixedHeader }">
                <navbar />
            </div>
            <div
                :style="{
                    height: `calc(100% -  ${navBarHeight} - ${tagsHeight})`,
                }"
            >
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import { AppMain, Navbar, Sidebar, HeadBar, UserInfo } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import AvatarBar from "@/layout/components/AvatarBar/index";

// import { mapState } from 'vuex'
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            updateView: true,
        };
    },
    name: "Layout",
    components: {
        AppMain,
        Navbar,
        Sidebar,
        UserInfo,
        HeadBar,
        // eslint-disable-next-line vue/no-unused-components
        AvatarBar,
    },
    mixins: [ResizeMixin],
    computed: {
        ...mapGetters([
            "sidebar",
            "device",
            "hasHeaderBar",
            "hasSideBar",
            "hasBreadBar",
            "headBarHeight",
            "fixedHeader",
            "tagsHeight",
            "sideBarWidth",
            "navBarHeight",
            "userInfoHeight",
            "menuMode",
        ]),
        classObj() {
            return {
                hideSidebar: !this.sidebar.opened,
                openSidebar: this.sidebar.opened,
                withoutAnimation: this.sidebar.withoutAnimation,
                mobile: this.device === "mobile",
            };
        },
    },
    created() {
        this.changeHeight();
    },
    mounted() {
        // this.updateView = false;
        // this.$nextTick(() => {
        //     this.updateView = true;
        // });
    },
    methods: {
        handleClickOutside() {
            this.$store.dispatch("app/closeSideBar", {
                withoutAnimation: false,
            });
        },
        changeHeight() {
            if (!this.hasHeaderBar) {
                this.$store.dispatch("settings/changeSetting", {
                    key: "headBarHeight",
                    value: "0px",
                });
            }

            if (!this.hasSideBar) {
                this.$store.dispatch("settings/changeSetting", {
                    key: "sideBarWidth",
                    value: "0px",
                });
            }
            if (!this.hasBreadBar) {
                this.$store.dispatch("settings/changeSetting", {
                    key: "navBarHeight",
                    value: "0px",
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

#sideBar {
    transition: width 0.28s;
    width: $sideBarWidth;
    background-color: $menuBg;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1001;
    overflow: hidden;
}

#main-container {
    width: calc(100% - #{$sideBarWidth});
    //height: calc(100% - #{$headBarHeight});
    position: fixed;
    //top: $headBarHeight;
    bottom: 0;
    left: $sideBarWidth;
    z-index: 1001;
}

.sidebar-inner {
}

//.hor-sidebar{
//  height: inherit;
//}
::v-deep.hor-sidebar,
::v-deep.hor-sidebar > ul,
::v-deep.hor-sidebar > ul > li,
::v-deep.hor-sidebar > ul > li > .el-submenu__title {
    height: inherit;
    line-height: $headBarHeight;
}

// 滚动条的宽度
.sidebar-inner::-webkit-scrollbar {
    width: 0; // 横向滚动条
    //height: 50px; // 纵向滚动条 必写
    //background-color: rgb(210, 220, 249);
}

// 滚动条的滑块
//#sidebar-inner::-webkit-scrollbar-thumb {
//  background-color: #F0F0F0;
//  border-radius: 15px;
//}

#sideBar {
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

#sideBar::-webkit-scrollbar {
    display: none;
}
</style>
