<template>
    <el-date-picker
        :placeholder="placeholder"
        v-bind="$attrs"
        v-on="$listeners"
        :type="type"
        value-format="yyyy-MM-dd"
    >
    </el-date-picker>
    <!-- :default-time="type === 'datetime' ? ['00:00:00', '23:59:59'] : ''" -->
</template>

<script>
export default {
    name: "GlobalElDatePicker",
    props: {
        type: {
            type: String,
            default: "date",
        },
        placeholder: {
            type: String,
            default: "请选择日期",
        },
    },
};
</script>

<style lang="scss" scoped>
.el-date-editor {
    ::v-deep .el-range-input {
        background-color: #f0f0f0;
    }
}
</style>
