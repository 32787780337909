const src = '/data/server/escort/web/'

/**
 * 列表查询树型菜单
 */
export const getCourseType = src + 'courseType/tree/'

/**
 * 课程类型操作
 */
export const courseType = src + 'courseType/'

/**
 * 课程类型批量删除
 */
export const courseTypeBatch = src + 'courseType/batch/'

/**
 * 课程类型下级列表
 */
export const courseTypeChild = src + 'courseType/children/'

/**
 * 课程类型上级列表
 */
export const courseTypeParent = src + 'courseType/parent/'
