<template>
    <div id="div1234567">
        <slot></slot>
        <div></div>
        <!--        <div ref="toolbar" className="toolbar"/>-->
        <!--        <div ref="editor" className="text"/>-->
    </div>
</template>

<script>
import E from "wangeditor";
import { axiosFile } from "@/utils/request";
import { fileUrl, token, srcUrl } from "@/api/Global";
import { Loading } from "element-ui";

export default {
    name: "RichText",

    mounted() {
        this.$nextTick(() => {
            this.createEditor();
        });
    },
    data() {
        return {
            editor: null,
            html: "<p></p>",
            toolbarConfig: {},
            editorConfig: { placeholder: "请输入内容..." },
            mode: "default", // or 'simple'
            loadingInstance: null,
        };
    },

    methods: {
        createEditor() {
            this.editor = new E("#div1234567");

            // 默认情况下，显示所有菜单
            this.editor.config.menus = [
                "head",
                "bold",
                "fontSize",
                "fontName",
                "italic",
                "underline",
                "strikeThrough",
                "indent",
                "lineHeight",
                "foreColor",
                "backColor",
                "link",
                "list",
                "todo",
                "justify",
                "quote",
                "emoticon",
                "image",
                // "video",
                "table",
                "code",
                "splitLine",
                "undo",
                "redo",
            ];

            const self = this;

            this.editor.config.customUploadImg = function (
                resultFiles,
                insertImgFn
            ) {
                const formData = new FormData();
                formData.append("fileType", "RICH_TEXT_FILE");
                formData.append("file", resultFiles[0]);
                axiosFile(
                    "/data/server/escort/web/file",
                    formData,
                    null,
                    "post"
                ).then((res) => {
                    let aaa = fileUrl + res.data.filePath;
                    // insertImgFn(aaa);

                    // 往编辑器插入 html 内容
                    that.editor.cmd.do(
                        "insertHTML",
                        `<img src="${aaa}" style="max-width:100%;" contenteditable="false"/>`
                    );
                });
            };
            // this.editor.config.uploadVideoServer = '/data/server/escort/web/file';
            let that = this;

            this.editor.config.uploadVideoServer =
                srcUrl + "/data/server/escort/web/file";
            this.editor.config.uploadVideoMaxSize = 1 * 50 * 1024 * 1024;
            this.editor.config.uploadVideoAccept = ["mp4"];
            this.editor.config.uploadVideoParams = {
                fileType: "RICH_TEXT_FILE",
            };
            this.editor.config.uploadVideoName = "file";

            this.editor.config.uploadVideoHeaders = {
                Authorization: sessionStorage.getItem("Authorization"),
            };

            this.editor.config.uploadVideoHooks = {
                // 上传视频之前
                before: function (xhr, editor, resData) {
                    that.loadingInstance = Loading.service({
                        fullscreen: true,
                        background: "rgba(0, 0, 0, 0.3)",
                        text: "视频上传中，请稍后",
                    });
                    console.log(resData[0].size / (1024 * 1024));
                    let M50 = resData[0].size / (1024 * 1024);

                    if (M50 >= 50) {
                        that.$nextTick(() => {
                            // 以服务的方式调用的 Loading 需要异步关闭
                            that.loadingInstance.close();
                        });
                        // 可阻止视频上传
                        that.$message({
                            message: "视频大小不能超过50M",
                            type: "warning",
                        });
                        return null;
                    }
                },
                // 视频上传并返回了结果，视频插入已成功
                success: function (xhr) {
                    console.log("success", xhr);
                    that.$nextTick(() => {
                        // 以服务的方式调用的 Loading 需要异步关闭
                        that.loadingInstance.close();
                    });
                },
                // 视频上传并返回了结果，但视频插入时出错了
                fail: function (xhr, editor, resData) {
                    console.log("fail", resData);

                    that.$nextTick(() => {
                        // 以服务的方式调用的 Loading 需要异步关闭
                        that.loadingInstance.close();
                    });
                },
                // 上传视频出错，一般为 http 请求的错误
                error: function (xhr, editor, resData) {
                    console.log("error", xhr, resData);
                    that.$message({
                        message: "视频大小不能超过50M",
                        type: "warning",
                    });

                    that.$nextTick(() => {
                        // 以服务的方式调用的 Loading 需要异步关闭
                        that.loadingInstance.close();
                    });
                },
                // 上传视频超时
                timeout: function (xhr) {
                    console.log("timeout");
                },
                // 视频上传并返回了结果，想要自己把视频插入到编辑器中
                // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
                customInsert: function (insertVideoFn, result) {
                    // result 即服务端返回的接口
                    console.log(result);
                    let aaa = fileUrl + result.data.filePath;
                    // insertVideoFn(aaa);

                    // 往编辑器插入 html 内容
                    that.editor.cmd.do(
                        "insertHTML",
                        `<video src="${aaa}" poster="https://test.roadmaintain.cn:60001/video-test.png"  controls="controls" style="video/mp4;max-width: 100%"></video>`
                    );

                    that.$nextTick(() => {
                        // 以服务的方式调用的 Loading 需要异步关闭
                        that.loadingInstance.close();
                    });

                    // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
                    // insertVideoFn(result.data.url)
                },
            };

            // this.editor.config.customUploadVideo = function (resultFiles, insertVideoFn) {
            //     // resultFiles 是 input 中选中的文件列表
            //     // insertVideoFn 是获取视频 url 后，插入到编辑器的方法
            //     const formData = new FormData();
            //     formData.append("fileType", "RICH_TEXT_FILE");
            //     formData.append("file", resultFiles[0]);
            //     axiosFile('/data/server/escort/web/file', formData, null, 'post').then(res => {
            //         let aaa = fileUrl + res.data.filePath;
            //         // insertVideoFn(aaa);
            //
            //         // 往编辑器插入 html 内容
            //         that.editor.cmd.do(
            //             'insertHTML',
            //         `<div data-v-86f21094="" class="toolbar"></div><div data-v-86f21094="" class=text"></div><p><video src="${aaa}" poster="https://test.roadmaintain.cn:60001/video-test.png"  controls="controls" style="video/mp4;max-width: 100%"></video></p>`
            //
            //         );
            //
            //     })
            //
            //
            // }

            this.editor.config.onchange = (html) => {
                self.$emit("get-content", {
                    content: html,
                    noHtml: this.editor.txt.text(),
                });
            };
            this.editor.create(); // 创建富文本实例
        },
    },

    beforeDestroy() {
        // 销毁编辑器
        this.editor.destroy();
        this.editor = null;
    },
};
</script>

<style scoped></style>

<style></style>
