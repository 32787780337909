<template>
    <div id="head" class="flex">
        <Logo
            v-if="sidebarLogo && hasSideBar"
            :style="{
                width: sideBarWidth,
            }"
        />
        <HeadLeft v-else-if="sidebarLogo" />
        <slot name="sideBar" />
        <slot name="avatar" />
    </div>
</template>

<script>
import Logo from "@/layout/components/Sidebar/Logo";
import HeadLeft from "@/layout/components/HeadLeft";
import { mapGetters } from "vuex";
export default {
    name: "Index",
    components: {
        Logo,
        HeadLeft,
    },
    computed: {
        ...mapGetters(["sidebarLogo", "sideBarWidth", "hasSideBar"]),
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
#head {
    height: $headBarHeight !important;
    background-color: $panGreen;
}
</style>
