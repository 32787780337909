<template>
    <div class="navbar flex justify-between">
        <div class="flex align-center">
            <hamburger
                :is-active="sidebar.opened"
                class=""
                @toggleClick="toggleSideBar"
            />
            <breadcrumb class="" />
        </div>
        <AvatarBar />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";
import AvatarBar from "@/layout/components/AvatarBar";
export default {
    components: {
        Breadcrumb,
        Hamburger,
        AvatarBar,
    },
    computed: {
        ...mapGetters(["sidebar"]),
    },
    methods: {
        toggleSideBar() {
            this.$store.dispatch("app/toggleSideBar");
            if (this.sidebar.opened) {
                this.$store.dispatch("settings/changeSetting", {
                    key: "sideBarWidth",
                    value: "210px",
                });
            } else {
                this.$store.dispatch("settings/changeSetting", {
                    key: "sideBarWidth",
                    value: "65px",
                });
            }
        },
        async logout() {
            this.$router.push(`/login?redirect=${this.$route.fullPath}`);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.navbar {
    height: $navBarHeight;
    overflow: hidden;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

    .hamburger-container {
        line-height: 46px;
        height: 100%;
        float: left;
        cursor: pointer;
        transition: background 0.3s;
        -webkit-tap-highlight-color: transparent;

        &:hover {
            background: rgba(0, 0, 0, 0.025);
        }
    }

    .breadcrumb-container {
        float: left;
    }

    .errLog-container {
        display: inline-block;
        vertical-align: top;
    }
}
</style>
