<template>
    <transition name="sidebarLogoFade">
        <div v-if="hasUserInfo" id="user-info">
            <img v-if="logoPath" :src="fileUrl + logoPath" class="logo" />
            <h1 v-else class="sidebar-title">{{ name }}</h1>
        </div>
    </transition>
</template>

<script>
import { fileUrl } from "@/api/Global";
import { mapGetters } from "vuex";
export default {
    name: "Index",
    data() {
        return {
            fileUrl,
            name: "",
            logoPath: "",
        };
    },
    computed: {
        ...mapGetters(["hasUserInfo"]),
    },
    mounted() {
        if (sessionStorage.logoPath && sessionStorage.logoPath != "null") {
            this.logoPath = sessionStorage.logoPath;
        }
        if (sessionStorage.iconPath && sessionStorage.iconPath != "null") {
            let link = document.createElement("link");
            link.setAttribute("rel", "icon");
            link.setAttribute("href", this.fileUrl + sessionStorage.iconPath);

            document.getElementsByTagName("head")[0].appendChild(link);
        }
        if (sessionStorage.CompanyName) {
            this.name = sessionStorage.CompanyName;
        } else {
            this.name = sessionStorage.UserName;
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
#user-info {
    // height: $userInfoHeight;
    background-color: #487ee0;
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
    // line-height: $userInfoHeight;
    text-align: center;
}

.logo {
    width: 210px;
    height: 80px;
}
.sidebar-title {
    display: inline-block;
    margin: 0;
    color: #fff;
    font-weight: 600;
    line-height: 30px;
    font-size: 18px;
    font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
    vertical-align: middle;
    letter-spacing: 3px;
    padding: 10px 5px;
    box-sizing: border-box;
}
</style>
