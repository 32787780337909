import router from "./router";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import getPageTitle from "@/utils/get-page-title";
NProgress.configure({ showSpinner: false }); // NProgress Configuration

router.beforeEach(async (to, from, next) => {
    // start progress bar
    NProgress.start();
    // set page title
    document.title = getPageTitle(to.meta.title);
    // set page title
    if (to.path === "/login") {
        // if is logged in, redirect to the home page
        next();
        NProgress.done();
    } else {
        const token = sessionStorage.getItem("Authorization");
        if (token === null || token === "") {
            // next(`/login?redirect=${to.path}`)
            next("/login");
        } else {
            next();
        }
        // next()
        NProgress.done();
    }
});

router.afterEach(() => {
    // finish progress bar
    NProgress.done();
});
