<template>
    <div>
        <el-upload
            :action="srcUrl + '/data/server/escort/web/file'"
            list-type="picture-card"
            :headers="{
                Authorization: token,
            }"
            :limit="limit"
            :accept="accept"
            :before-upload="beforeUpload"
            :show-file-list="false"
            :on-success="onSuccess"
            v-if="fileList.length < limit"
        >
            <i class="el-icon-plus"></i>
        </el-upload>

        <div class="img-box" v-else>
            <div
                v-for="(file, index) in fileList"
                :key="index"
                class="img-item"
            >
                <img :src="fileUrl + file.filePath" class="avatar" />
                <div class="del-box">
                    <div class="icon-box">
                        <i
                            class="el-icon-delete"
                            style="
                                color: #ffffff;
                                font-size: 22px;
                                cursor: pointer;
                            "
                            @click="del(index)"
                        ></i>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
    </div>
</template>
<script>
import { fileUrl, token, srcUrl } from "@/api/Global";

export default {
    model: {
        prop: "fileList",
        event: "change",
    },
    props: {
        fileList: { type: Array, require: true },
        limit: { type: Number, default: 4 },
        accept: { type: String, default: ".png,.jpg" },
    },
    computed: {
        // token() {
        //     return token;
        // },
        srcUrl() {
            return srcUrl;
        },
        fileUrl() {
            return fileUrl;
        },
    },
    data() {
        return {
            token: window.sessionStorage.getItem("Authorization"),
            dialogImageUrl: "",
            dialogVisible: false,
        };
    },
    methods: {
        del(index) {
            console.log("del");
            const fileList = JSON.parse(JSON.stringify(this.fileList));
            fileList.splice(index, 1);
            this.$emit("change", fileList);
        },

        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        beforeUpload(file) {
            if (this.accept === ".png,.jpg") {
                if (file.type === "image/png" || file.type === "image/jpeg") {
                    return true;
                } else {
                    this.$message({
                        message: "请上传png，jpg格式的图片文件",
                        type: "warning",
                        center: true,
                    });
                    return false;
                }
            } else if (this.accept === ".ico") {
                if (file.type === "image/x-icon") {
                    return true;
                } else {
                    this.$message({
                        message: "请上传ico格式的图片文件",
                        type: "warning",
                        center: true,
                    });
                    return false;
                }
            }
        },
        onSuccess(file) {
            console.log("onSuccess");
            const temp = JSON.parse(JSON.stringify(this.fileList)) || [];
            if (file.data.filePath) {
                temp.push(
                    Object.assign(
                        { url: this.fileUrl + "/" + file.data.filePath },
                        file.data
                    )
                );
                this.$emit("change", temp);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 146px;
    height: 146px;
    line-height: 146px;
    text-align: center;
}
.avatar {
    width: 146px;
    height: 146px;
    display: block;
    border-radius: 4px;
}
.img-item {
    width: 146px;
    height: 146px;
    position: relative;
    border-radius: 4px;
}
.img-item:hover .del-box {
    width: 146px;
    height: 146px;
    display: inline;
    position: absolute;
    z-index: 8;
    top: 0;
    border-radius: 4px;
    background-color: #060a0e82;
}
.del-box {
    display: none;
}
.icon-box {
    width: 146px;
    height: 146px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
}
</style>
