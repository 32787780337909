const src = '/oauth/server/escort/web/'
/**
 * 岗位接口操作
 */
export const position = src + 'position/' // {id}

/**
 * 岗位列表-配置企业 接口操作
 */
export const positionCompany = src + 'position/company/' // {id}


/**
 *
 通过一批公司id查询公有系统岗位列表
 */
export const positionCompanyIds = src + 'position/companyIds' //

/**
 * 分配岗位-下拉框-企业管理
 */
export const dropComPosition = src + 'position/company/dropDown'

/**
 * 分配岗位-下拉框-人员管理
 */
export const dropUserPosition = src + 'position/user/dropDown'
