<template>
    <el-select
        filterable
        clearable
        v-bind="$attrs"
        v-on="$listeners"
        placeholder="请选择公司"
    >
        <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.companyName"
            :value="item.id"
        >
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: "GlobalCompanySelect",
    data() {
        return {
            options: [],
            value: "",
        };
    },
    async created() {
        let { code, data } = await this.$api.get(
            "/oauth/server/escort/web/company?currentPage=1&pageSize=1000"
        );
        if (code == 200) {
            this.options = data.content;
        }
    },
};
</script>

<style lang="scss" scoped></style>
