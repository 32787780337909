import Vue from "vue";

// 全局组件(2023年6月,前端废物封装)
import "./components_new/index.js";

import "normalize.css/normalize.css"; // a modern alternative to CSS resets
/* 引入element */
import "@/plugins/element-ui";
import "./styles/element-variables.scss";
/* vxe-table */
import "@/plugins/vxe-table";
/* vxe-table */
import "@/styles/index.scss";

/* 全局修改form表单框背景色 */
import App from "./App";
import store from "./store";
import router from "./router/index.js";

// import '@/assets/icon/iconfont.eot'
import "@/assets/icon/iconfont.ttf";
import "@/assets/icon/iconfont.woff";
import "@/assets/icon/iconfont.woff2";
// import '@/assets/icon/iconfont.svg'
import "@/assets/icon/iconfont.css";

import "./icons"; // icon
import "./permission"; // permission control
import "./utils/error-log"; // error log

import * as filters from "./filters"; // global filters
import { textNull } from "@/utils/validated";
import { srcUrl, fileUrl, CompanyType, RegionCode, Admin } from "@/api/Global";
import { axiosReq } from "@/utils/request";
import service from "@/utils/request";
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import { hasBtn } from "@/utils/hasBtnPermi";
import dataV from "@jiaminghi/data-view";
import "./pages/components/index";

Vue.use(dataV);

Vue.filter("g_filter_empty", (val) => {
    //全局过滤器  默认显示
    if (val === 0 || val === "0") {
        return val;
    }
    return val ? val : "--";
});

Vue.mixin({
    methods: {
        rowStyleFn({ rowIndex }) {
            if ((rowIndex + 1) % 2 === 0) {
                return "background-color: #F5F9FC;";
            }
        },
        headerStyle,
    },
});

Vue.prototype.$axiosReq = axiosReq;
Vue.prototype.$api = service;
Vue.prototype.$textNull = textNull; // 挂载到Vue实例上面
Vue.prototype.$srcUrl = srcUrl; // 挂载到Vue实例上面
Vue.prototype.$fileUrl = fileUrl; // 挂载到Vue实例上面
Vue.prototype.$headerStyle = headerStyle; // 挂载到Vue实例上面
Vue.prototype.$rowStyle = rowStyle; // 挂载到Vue实例上面
Vue.prototype.$hasBtn = hasBtn; // 挂载到Vue实例上面

// register global utility filters
Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;

new Vue({
    el: "#app",
    router,
    store,
    render: (h) => h(App),
});
