import safeTrain from "./safeTrain.js";
import sysConfig from "./sysConfig.js";
import organize from "./organize.js";
import businessCnf from "./businessCnf.js";
import knowledgeManage from "./knowledgeManage.js";
import carTechnologyManage from "./carTechnologyManage.js";

export default [
    {
        path: "/supervise",
        meta: { title: "首页" },
        component: () =>
            import(
                /* webpackChunkName: "supervise" */ "../views/supervise/index.vue"
            ),
    },
    // 学习报表
    {
        path: "/safeTrain",
        redirect: "/safeTrain/safeTrain",
        component: {
            render: (h) => h("router-view"),
        },
        children: safeTrain,
    },

    // 管理中心
    {
        path: "/organize",
        redirect: "/organize/company",
        component: {
            render: (h) => h("router-view"),
        },
        children: organize,
    },

    // 业务中心
    {
        path: "/businessCnf",
        redirect: "/businessCnf/standardCourse",
        component: {
            render: (h) => h("router-view"),
        },
        children: businessCnf,
    },

    // 信息管理
    {
        path: "/infoManage",
        redirect: "/infoManage/news",
        component: {
            render: (h) => h("router-view"),
        },
        children: [
            {
                path: "/infoManage/news",
                meta: { title: "新闻管理" },
                component: () =>
                    import(
                        /* webpackChunkName: "news" */ "../views/news/index.vue"
                    ),
            },
        ],
    },

    // 系统配置
    {
        path: "/sysConfig",
        redirect: "/sysConfig/dataDict",
        component: {
            render: (h) => h("router-view"),
        },
        children: sysConfig,
    },

    // 知识库管理
    {
        path: "/knowledgeManage",
        redirect: "/knowledgeManage/knowledgeList",
        component: {
            render: (h) => h("router-view"),
        },
        children: knowledgeManage,
    },
    // 车辆技术管理
    {
        path: "/carTechnologyManage",
        redirect: "/carTechnologyManage/carRecordManage",
        component: {
            render: (h) => h("router-view"),
        },
        children: carTechnologyManage,
    },
];
